import { useState, useEffect } from "react";
import { getAuthToken, deleteAuthToken, isAuthCookieExpired, getDecodedAuthToken } from "../util/auth";
import { useHistory } from 'react-router-dom';
import { updateJWTIfNecessary } from "../util/jwtUpdateService";

export function useAuthentication() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const checkAuthentication = () => {
            const token = getAuthToken();
            if (token && !isAuthCookieExpired()) {
                setIsAuthenticated(true);
                return;
            }
            if (token && isAuthCookieExpired()) {
                deleteAuthToken();
                history.push("/login");
                setIsAuthenticated(false);
                return;
            }
            if (!token) {
                setIsAuthenticated(false);
                return;
            }
        }
        checkAuthentication();
    }, []);

    const logout = () => {
        deleteAuthToken();
        setIsAuthenticated(false);
    }

    return { isAuthenticated, logout };
}

export function useAuthorization() {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuthorization = async () => {
            try {
                await updateJWTIfNecessary();

                if (getAuthToken()) {
                    if (getDecodedAuthToken().subscription_status !== "active" && getDecodedAuthToken().subscription_status !== "trialing") {
                        setIsAuthorized(false);
                        return;
                    }
                    setIsAuthorized(true);
                }
            } catch (err) {
                console.error(err);
                deleteAuthToken();
                setIsAuthorized(false);
            } finally {
                setIsLoading(false);
            }

        };
        checkAuthorization();
    }, []);
    return { isAuthorized, isLoading };
}