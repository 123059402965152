import React, { useState } from "react";
import ActivityCalendar from "react-activity-calendar";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import "./progressCalendar.css";


const explicitTheme = {
  light: ['#f0f0f0', '#ffcc99', '#ffb870', '#ffad5c', '#ff9933'],
  dark: ['#383838', '#4D455D', '#7DB9B6', '#F5E9CF', '#E96479'],
};

// TODO: make progres calendar scrollable to the side
const ProgressCalendar = ({ customerPracticeActivityData, loading }) => {
  const [activityDetail, setActivityDetail] = useState(null);

  //todo improve that function
  function preparePractiveActivityState(practiceActivity) {
    const initialPracticeActivity = [];
    const currentDateMinusSixMonths = new Date();
    currentDateMinusSixMonths.setMonth(currentDateMinusSixMonths.getMonth() - 4);
    const formattedDayMinusSixMonths = currentDateMinusSixMonths.toISOString().split('T')[0];
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    // if no practice activity in db => add 6 months ago and today to initial state for empty calendar
    if (!practiceActivity || practiceActivity.length === 0) {
      initialPracticeActivity.unshift({ date: formattedDayMinusSixMonths, count: 0, level: 0 });
      initialPracticeActivity.push({ date: formattedToday, count: 0, level: 0 });
      return initialPracticeActivity;
    }

    const oldestPracticeActivity = practiceActivity.reduce(((prev, current) => (prev.date < current?.date) ? prev : current))?.date;
    const newestPracticeActivity = practiceActivity.reduce(((prev, current) => (prev.date > current?.date) ? prev : current))?.date;

    // if oldest practice activity is newer than 6 months ago => add 6 months ago to initial state
    if (new Date(formattedDayMinusSixMonths) < new Date(oldestPracticeActivity)) {
      initialPracticeActivity.unshift({ date: formattedDayMinusSixMonths, count: 0, level: 0 });
    }
    initialPracticeActivity.push(...practiceActivity)

    // if today was not practiced yet => add today to initial state to show empty last day
    if (new Date(formattedToday) > new Date(newestPracticeActivity)) {
      initialPracticeActivity.push({ date: formattedToday, count: 0, level: 0 });
    }

    // determine level for each practice activity
    const enhancedPractiveActivity = initialPracticeActivity.map(item => {
      let level = 0;
      if (item?.practiceDurationMinutes >= 5 || item?.count >= 1) {
        level++;
      }
      if (item?.practiceDurationMinutes >= 30 || item?.count >= 6) {
        level++;
      }
      if (item?.practiceDurationMinutes >= 60 || item?.count >= 12) {
        level++;
      }
      if (item?.practiceDurationMinutes >= 120 || item?.count >= 16) {
        level++;
      }

      return {
        ...item,
        level: level
      }
    })

    enhancedPractiveActivity.sort((a, b) => new Date(a.date) - new Date(b.date));
    return enhancedPractiveActivity;
  }
  const practiceActivityData = preparePractiveActivityState(customerPracticeActivityData);

  const onDayClick = (event) => {
    if (event.date === activityDetail?.date) {
      setActivityDetail(null);
      return;
    }
    setActivityDetail(event);
  }

  return (
    <>
      <div className="progress-calendar">
        <ActivityCalendar
          loading={loading}
          style={{}}
          data={practiceActivityData || [{ date: "2022-06-01", count: 0, level: 0 }]}
          renderBlock={(block, activity) =>
            React.cloneElement(block, {
              'data-tooltip-id': 'react-tooltip',
              'data-tooltip-html': `${activity?.count} lessons practised ${activity?.date}`,
            })
          }
          eventHandlers={{
            onClick: (event) => (activity) => {
              onDayClick(activity);
            }
          }}
          blockRadius={3}
          blockMargin={4}
          colorScheme="light"
          showWeekdayLabels={true}
          theme={explicitTheme}
          labels={{
            legend: {
              less: "Less",
              more: "More"
            },
            months: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ],
            weekdays: ["Sun", "M", "Tue", "W", "Thu", "F", "Sat"],
            totalCount: ' ',

          }}
        />
        <ReactTooltip id="react-tooltip" style={{ fontSize: "14px" }} />
        {activityDetail ?
          <div className="activity-detail">
            <div className="activity-detail-item">{`${activityDetail.date}`}</div>
            <div className="activity-detail-item">{activityDetail.count ? `${activityDetail.count} lessons practiced` : "No lessons practiced"}</div>
            <div className="activity-detail-item">{activityDetail.count ? `${customerPracticeActivityData.find((item) => item.date === activityDetail.date)?.practiceDurationMinutes} min in total practiced` : null}
            </div>
          </div> : null}
      </div>
    </>
  );
}

export default ProgressCalendar;