import React, { Component } from "react";
import queryString from 'query-string';
import { verifyMail, requestVerificationMail } from '../../services/loginServiceGateway'
import { storeAuthToken, deleteAuthToken, getAuthToken } from "../../util/auth";
import "./verifyMail.css";
import jwtDecode from "jwt-decode";

const Joi = require("joi");


const emailSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
});
class VerifyMail extends Component {

  state = {
    emailFormData: {
      email: ""
    },
    emailValidationError: "",
    successMessage: ""
  }
  async componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const verifyMailToken = query.secret;

    if (verifyMailToken) {
      let res;
      try {
        res = await verifyMail(verifyMailToken);
        deleteAuthToken();
        storeAuthToken(res.data);
        this.props.history.push("/launch-overview");
      } catch (err) {
        console.error(err);
      }
    }

    try {
      const authToken = getAuthToken();
      const decodedAuthToken = jwtDecode(authToken);
      if (decodedAuthToken.verification_state) {
        this.props.history.push("/launch-overview");
        return;
      }
    } catch (err) {
      console.log(err)
    }
  }

  validate = () => {
    const { error } = emailSchema.validate(this.state.emailFormData, { abortEarly: false });
    if (!error) return null;
    console.log(error)
    return error;
  };

  handleChange = ({ currentTarget: input }) => {
    const emailFormData = { ...this.state.emailFormData };
    emailFormData[input.name] = input.value;
    this.setState({ emailFormData });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const emailValidationError = this.validate();
    this.setState({ emailValidationError: emailValidationError || {} });
    if (emailValidationError) return;

    const email = this.state.emailFormData.email;
    let updatedState = { ...this.state };
    try {
      await requestVerificationMail(email);
      updatedState.emailFormData.email = "";
      updatedState.successMessage = `Validation email send successfully to ${email}, please check your emails!`;
      this.setState(updatedState);
    }
    catch (error) {
      updatedState.emailFormData.email = "";
      updatedState.successMessage = `The email ${email} is already verified or does not exist.`;
      this.setState(updatedState);
    }
  };

  render() {


    return (
      <div className="verify-mail-body">
        <div className="verify-mail-card">
          <div className="verify-mail-content">
            <h1>Check your emails!</h1>
            <p>Only one step left! Please check your email inbox. A mail with a verification button is waiting for you!</p>
            <p>You know what to do.</p>
            <p>In case you didn't receive an email, please check your spam folder first.</p>
            <p>Otherwise, you can always request another one :)</p>
            <div className="email-verification-container">
              <form className="request-validation-mail-form" onSubmit={this.handleSubmit}>
                <label htmlFor="email">
                </label>
                <input
                  className="email-verification-input"
                  type="text"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.emailFormData.email}
                  error={this.state.emailValidationError}
                  placeholder="@" />
                <div id="verify-mail-form-error">
                  {this.state.emailValidationError.message && <div className="validation-error" >{this.state.emailValidationError.message}</div>}
                </div>
                <div className="get-btn">
                  <button
                    className="validation-mail-submit-btn"
                    type="submit"
                    disabled={!this.state.emailFormData.email}
                  >Request new verification email</button>
                </div>
              </form>
              {this.state.successMessage && <div className="success-message">{this.state.successMessage}</div>}
            </div>

          </div>
        </div>
      </div >

    )
  };
};

export default VerifyMail;