import { Tooltip } from 'react-tooltip';
import { skillDisplayName } from "../common/globalStrings.jsx"
import './progressGauge.css';
import { icons } from '../common/icons.jsx';
import { levelNames } from '../common/globalStrings.jsx';
import { updateSkillPreference } from '../../services/scheduleServiceGateway'
import { getAuthToken } from '../../util/auth';

export default function ProgressGauge({ customerProgress, skillPref, updateProgressState, icon, size, isClickableForLevelUp }) {
  let progressInPercent = 0;
  let skillLevelInNumber = 0;

  function getLevelProgress() {
    if (!customerProgress) return 0;
    const skillInfo = customerProgress.find((e) => e.skillName === skillPref) || {};
    const { levelProgress, skillLevel } = skillInfo;
    skillLevelInNumber = Math.max(levelNames.indexOf(skillLevel), 0);
    progressInPercent = levelProgress || 0;
  };

  getLevelProgress();

  let width = 100;
  if (size === 'small') {
    width = 80;
  }
  const strokeWidth = .08 * width;
  const radius = width / 2;
  const radiusMinusStrokeWidth = radius - strokeWidth;
  let isReadyForLevelUp = false;
  if (isClickableForLevelUp) {
    isReadyForLevelUp = progressInPercent >= 80 && skillLevelInNumber < 7 && skillPref !== 'beginner_course';
  }
  let dashoffset = 100 - progressInPercent - 20;
  if (dashoffset < -20) dashoffset = -20;


  const levelUpSkillPref = async (skill, level) => {
    const updatedLevel = level + 1;
    try {
      await updateSkillPreference(getAuthToken(), { skillName: skill, selected: true, level: levelNames[updatedLevel] });
      await updateProgressState();
    } catch (err) {
      console.log("error in level up", err);
    }
  }

  return (
    <>
      <Tooltip anchorSelect={`.${skillPref}_tooltip`} style={{ fontSize: "14px", zIndex: 10 }}>
        {isReadyForLevelUp ? (
          <>
            Level Up <br /> {skillDisplayName[skillPref]}
          </>
        ) : (
          skillDisplayName[skillPref]
        )}

      </Tooltip>
      <div
        className={isReadyForLevelUp ? 'level-gauge-container lvl-up' : 'level-gauge-container'}
        onClick={isReadyForLevelUp ? () => levelUpSkillPref(skillPref, skillLevelInNumber) : null}
      >
        <svg className="level-gauge" width={width} height={width}>
          {/*first circle is needed to fix mix-blend-mode for safari*/}
          <circle
            r={radiusMinusStrokeWidth}
            fill="white"
            cx={radius}
            cy={radius}
            strokeWidth={strokeWidth}
            stroke="white"
          />

          <circle
            id="progress-indicator-circle-background"
            r={radiusMinusStrokeWidth}
            fill="black"
            cx={radius}
            cy={radius}
            strokeWidth={strokeWidth}
            stroke="white"
          />
          <circle
            id="progress-indicator-circle"
            className="animate-dash"
            r={radiusMinusStrokeWidth}
            cx={radius}
            cy={radius}
            strokeWidth={strokeWidth + 1}
            stroke="orange"
            fill="none"
            pathLength="120"
            strokeDasharray={[100, 100]}
            strokeDashoffset={dashoffset}
          />
          <circle
            id="progress-indicator-circle-spacer"
            r={radiusMinusStrokeWidth}
            cx={radius}
            cy={radius}
            strokeWidth={strokeWidth + 2}
            stroke="black"
            fill="none"
            pathLength="120"
            strokeDasharray={[100, 100]}
            strokeDashoffset={100 - 20}
          />
        </svg>
        <div className="overlay-icon-gauge">
          <a className={`${skillPref}_tooltip`}>
            {isReadyForLevelUp ?
              <div className="svg-container">
                <div className="svg-animation">
                  {icons.get('level_up_mini')}
                </div>
                <div className="svg-animation animation-delayed">
                  {icon}
                </div>
              </div>
              :
              <div className="">
                {icon}
              </div>
            }
          </a>
        </div>
        <div className="overlay-progress-gauge">{parseInt(progressInPercent)}%</div>
        <div className="overlay-level-gauge">lvl {skillLevelInNumber}</div>
      </div>
    </>
  )
}
