import React, { useState, useRef, useEffect } from 'react';
import "./metronome.css"


  function Metronome(props) {
    const [bpm, setBpm] = useState(props.bpm ? props.bpm : 120);
    const [isRunning, setIsRunning] = useState(false);

    //necessary to update bpm while metronome is running
    useEffect(() => {
      //prevents metronome from starting when bpm is changed (happened only if metronome ran already - wtf)
      if(!isRunning) return;
      if(audioContextRef.current === null) return;
      clearInterval(intervalIDRef.current);
      intervalIDRef.current = setInterval(() => scheduler(), lookahead);
    }, [bpm]);
  
    // necessary to stop metronome when component is unmounted
    useEffect(() => {
      return () => {
        stop();
        clearInterval(intervalIDRef.current);
      }
    }, []);
    

    const lookahead = 25;          // How frequently to call scheduling function (in milliseconds)
    const scheduleAheadTime = 0.1;   // How far ahead to schedule audio (sec)
    let audioContextRef = useRef(null);
    let nextNoteTimeRef = useRef(0.0);     // when the next note is due
    let intervalIDRef = useRef(null);
  
  const nextNote = () => {
    // todo update bpm method for bpm
    let secondsPerBeat = 60.0 / bpm;
    nextNoteTimeRef.current += secondsPerBeat; // Add beat length to last beat time

  }

  const scheduleNote = (time) => {
    // create an oscillator
    const osc = audioContextRef.current.createOscillator();
    const envelope = audioContextRef.current.createGain();

    osc.frequency.value = 1400;
    envelope.gain.value = 1;
    envelope.gain.exponentialRampToValueAtTime(1, time + 0.001);
    envelope.gain.exponentialRampToValueAtTime(0.001, time + 0.02);

    osc.connect(envelope);
    envelope.connect(audioContextRef.current.destination);

    osc.start(time);
    osc.stop(time + 0.03);
  }

  const scheduler = () => {
    // while there are notes that will need to play before the next interval, schedule them and advance the pointer.
    while (nextNoteTimeRef.current < audioContextRef.current.currentTime + scheduleAheadTime) {
      scheduleNote(nextNoteTimeRef.current);
      nextNote();
    }
  }

  const start = () => {
    console.log("start");
    if (audioContextRef.current == null) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    if (isRunning) {
      audioContextRef.current.resume();
      return;
    };

    setIsRunning(true);

    nextNoteTimeRef.current = audioContextRef.current.currentTime + 0.05;

    intervalIDRef.current = setInterval(() => scheduler(), lookahead);
  }

  const stop = () => {
    setIsRunning(false);
    clearInterval(intervalIDRef.current);
  }

  const startStop = () => {
    if (isRunning) {
      stop();
    }
    else {
      start();
    }
  }

  const handleTempoChange = ({ currentTarget: input }) => {
    setBpm(input.value);
  }
  
  const handleButtonTempoChange = (value) => {
    let newBpm = parseInt(bpm) + parseInt(value);
    if (newBpm < 30) newBpm = 30;
    if (newBpm > 250) newBpm = 250;
    setBpm(newBpm);
  }

 
    
    return (
      <div className="metronome">
        <button id="play-button" className="" onClick={startStop}>
          {isRunning ? <div className="pause"></div> :
            <div className="play"></div>}
        </button>

        <div className="tempo-container">
          </div>
        <div>
          <input
            className="metronome-range"
            type="range"
            min="30"
            max="250" 
            onChange={handleTempoChange}
            value={bpm}
            />
        </div>
        <div id="tempo"> {bpm + "BPM"}</div>
        <button id="tempo-down" className="tempo-button" onClick={() => handleButtonTempoChange(-1)}>-</button>
        <button id="tempo-up" className="tempo-button" onClick={() => handleButtonTempoChange(+1)}>+</button>


      </div >
    );
}

export default Metronome;