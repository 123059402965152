import React, { useState } from 'react';
import './fixedMenuFooter.css';
import { CookieConsentPopUp } from '../cookieConsentPopUp';
import { cookieConsentDisplayed } from "../cookieHandler";
import { ReactComponent as CookieSVG } from '../../img/cookie.svg';
import { ReactComponent as UserAccountSVG } from '../../img/user_account.svg';
import { ReactComponent as ChartSVG } from '../../img/chart.svg';
import { ReactComponent as LevelUpSVG } from '../../img/level_up.svg';
import { ReactComponent as DrumXSticksSVG } from '../../img/drumx_logo_sticks.svg';
import { useHistory } from 'react-router-dom';

const FixedMenuFooter = () => {
    const history = useHistory();
    const [showCookiePref, setShowCookiePref] = useState(!cookieConsentDisplayed());

    return (
        <>
            <div className="fixed-footer-menu">
                <div className="fixed-menu-btn-container">
                    <button className="menu-btn" onClick={() => { history.push('/launch-overview') }}><DrumXSticksSVG className="footer-menu-icon" /></button>
                    <p>Practice</p>
                </div>
                <div className="fixed-menu-btn-container">
                    <button className="menu-btn" onClick={() => { history.push('/stats-and-progress') }}><ChartSVG className="footer-menu-icon" /></button>
                    <p>Stats</p>
                </div>
                <div className="fixed-menu-btn-container">
                    <button className="menu-btn" onClick={() => { history.push('/update-skills'); }}><LevelUpSVG className="footer-menu-icon" /></button>
                    <p>Level Up</p>
                </div>
                <div className="fixed-menu-btn-container">
                    <button className="menu-btn" onClick={() => { history.push('/account-settings') }}><UserAccountSVG className="footer-menu-icon" /></button>
                    <p>Profile</p>
                </div>
                <div>
                    <button className="menu-btn" onClick={() => setShowCookiePref(!showCookiePref)}><CookieSVG className="footer-menu-icon" /></button>
                    <p>Cookies</p>
                </div>
            </div>
            <div>
                {
                    showCookiePref ?
                        <CookieConsentPopUp showCookiePref={showCookiePref} setShowCookiePref={setShowCookiePref} />
                        :
                        null
                }
            </div>
        </>
    );
};

export default FixedMenuFooter;
