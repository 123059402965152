import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';

import './launchOverview.css';
import StatsOverview from './statsOverview';
import PracticeSelection from './practiceSelection';

import { receiveSchedule, postHistoryEntry, postCustomerPracticeActivity, getCustomerProgress } from '../../services/scheduleServiceGateway';
import { getAuthToken} from "../../util/auth";
import jwtDecode from "jwt-decode";
import Lesson from '../schedule/lesson';
import { PracticeSummary } from '../schedule/practiceSummary';
import { useCustomerSkillPreference, useCustomerProgress } from '../../hooks/fetchDataHook';
import { useAuthentication, useAuthorization } from '../../hooks/authHook';
import { useHistory } from 'react-router-dom';
import Loader from '../loader';

const initialPracticeSession = {
    schedule: [
        {
            lesson_id: 0,
            name: "",
            focus: "",
            min_level: "",
            max_level: "",
            pad_sufficient: false,
            video_url: "",
            description_text: "",
            start_bpm: 0,
            number_repetition: 0,
            type: "",
            practice_time: 0,
        }
    ],
    currentLessonIndex: 0,
    targetPracticeTime: 0,
    // necessary to calculate customer activity and update after each lesson
    practiceTimeBeforeCurrentExercise: 0,

    // todo: create array to store all evaluations for practice report
    evaluation: {
        rating_difficulty: "challenging"
    },
}

const evaluationNames = ['too_easy', 'easy', 'challenging', 'hard', 'too_hard'];

function LaunchOverview() {
    const history = useHistory();
    const { isAuthenticated, logout } = useAuthentication();
    const { isAuthorized, isLoading } = useAuthorization();

    const authToken = getAuthToken();
    const customerSkillPreference = useCustomerSkillPreference(authToken);
    const { customerProgress, refetchCustomerProgress } = useCustomerProgress(authToken);

    const [userName, setUsername] = useState('');
    const [showPracticeSession, setShowPracticeSession] = useState(false);
    const [practiceSession, setPracticeSession] = useState(initialPracticeSession);
    const [showPracticeSummary, setShowPracticeSummary] = useState(false);
    const [stopWatchTime, setStopWatchTime] = useState(0);
    const [practiceSummary, setPracticeSummary] = useState(null);
    const [customerUpdatedProgress, setCustomerUpdatedProgress] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0);
        if (!isLoading && !isAuthorized) {
            history.push('/renew-subscription');
        }
        let jwt = getAuthToken();
        const { firstname } = jwtDecode(jwt);
        setUsername(firstname);
    }, [isLoading, isAuthorized]);



    const getUpdatedCustomerProgress = async () => {
        try {
            const { data } = await getCustomerProgress(getAuthToken());
            setCustomerUpdatedProgress(data);
        } catch (err) {
            console.log("could not retrieve customerProgress", err)
        }
    }

    const resetPracticeSession = () => {
        setPracticeSession(initialPracticeSession);
    }

    const getPracticeSession = async (selectedSkillPrefForSchedule, practiceTime, isPadOnly) => {
        // TODO: refactor backend so that level is not needed
        // TODO: refactor and use practiceSession.jsx to abstract the schedule
        let skillPreferences = selectedSkillPrefForSchedule.map((skillPref) => {
            return { focus: skillPref, level: customerSkillPreference.find((pref) => pref.focus === skillPref).level }
        });
        try {
            let { data } = await receiveSchedule(getAuthToken(), skillPreferences, practiceTime, isPadOnly);
            if (data.schedule.length === 0) {
                throw new Error("no schedule received");
            }
            setPracticeSession({ ...practiceSession, schedule: data.schedule, targetPracticeTime: practiceTime });
            setShowPracticeSession(true);
        } catch (err) {
            console.error(`Could not receive practice session: ${err}`);
            throw new Error("Could not receive practice session");
        }
    }

    // todo: finish exercise part of abstacted schedule
    const finishExercise = async (lessonMastered) => {
        const isLastLesson = practiceSession.currentLessonIndex === practiceSession.schedule.length - 1;

        // create lesson entry for history
        const lessonEntry = {
            lessonId: practiceSession.schedule[practiceSession.currentLessonIndex].lesson_id,
            type: practiceSession.schedule[practiceSession.currentLessonIndex].type,
            ratingDifficulty: lessonMastered ? evaluationNames[0] : practiceSession.evaluation.rating_difficulty,
            skipLesson: lessonMastered
        }

        try {
            await postHistoryEntry(getAuthToken(), lessonEntry);
        } catch (err) {
            console.error("error in posting history entry", err);
        }

        // post customer practice activity after each lessons => calculate practice time to add
        try {
            const lessonPracticeTime = stopWatchTime - practiceSession.practiceTimeBeforeCurrentExercise;
            const practiceDurationMinutes = lessonPracticeTime < 60 ? 1 : parseInt(lessonPracticeTime / 60);
            // countLessonsPracticed is always 1 as we post after each lesson
            await postCustomerPracticeActivity(getAuthToken(), { countLessonsPracticed: 1, practiceDurationMinutes: practiceDurationMinutes });
        } catch (err) {
            console.error("error in postCustomerPracticeActivity", err)
        }

        if (!isLastLesson) {
            // increment lesson index and set practiceTimeBeforeCurrentExercise
            setPracticeSession({ ...practiceSession, currentLessonIndex: practiceSession.currentLessonIndex + 1, practiceTimeBeforeCurrentExercise: stopWatchTime, evaluation: { rating_difficulty: evaluationNames[2] } })
            return;
        }

        if (isLastLesson) {
            await getUpdatedCustomerProgress();
            setShowPracticeSession(false);
            setShowPracticeSummary(true);
            console.log("practice session finished")
            setPracticeSummary({ ...practiceSession, practiceTime: stopWatchTime })
            resetPracticeSession();
            setStopWatchTime(0);
            return;
        }
    }

    const handleEvaluation = (changeEvent) => {
        console.log('handle evalutation', stopWatchTime)
        changeEvent.persist();
        setPracticeSession(prevState => ({
            ...prevState,
            evaluation: { rating_difficulty: evaluationNames[changeEvent.target.value] }
        }));
    }

    const resumePractice = () => {
        console.log("resumePractice")
        setShowPracticeSession(true);
    }

    const handleStopWatchUpdate = (newTime) => {
        setStopWatchTime(newTime);
    }


    return (
        isLoading ? <Loader /> :
            showPracticeSession ?
                <Lesson
                    key={`lesson-${practiceSession.currentLessonIndex}`}
                    lesson={practiceSession?.schedule[practiceSession.currentLessonIndex]}
                    evaluation={practiceSession?.evaluation}
                    numberLessons={practiceSession?.schedule.length}
                    currentLessonNumber={practiceSession.currentLessonIndex}
                    currentLessonType={practiceSession?.schedule[practiceSession.currentLessonIndex].type}
                    schedule={practiceSession?.schedule}
                    username={userName}
                    handleEvaluation={handleEvaluation}
                    finishExercise={finishExercise}
                    stopWatchTime={stopWatchTime}
                    onTimeUpdate={handleStopWatchUpdate}
                />
                :
                <>
                    <Popup
                        open={showPracticeSummary}
                        modal
                        nested
                    >
                        {close => (
                            <div className="modal">
                                <PracticeSummary
                                    ctx={null}
                                    schedule={practiceSummary?.schedule}
                                    progress={customerProgress}
                                    updatedProgress={customerUpdatedProgress}
                                    updateProgressState={refetchCustomerProgress}
                                    totalPracticeTimeStopwatchSeconds={practiceSummary?.practiceTime}
                                    continueToOverview={() => { setShowPracticeSummary(false) }}
                                />
                            </div>
                        )}
                    </Popup>
                    <div className="launch-overview">
                        <StatsOverview userName={userName} customerSkillPreference={customerSkillPreference} />
                        {//todo add streak bubbles for the week
                        }
                        <div className="recommended-lesson-container">
                            {//TODO Jump right away or swipe to customize 
                                //TODO AND BACK
                            }
                            <h1 className="recommended-heading">Jump right into your practice session</h1>

                            <PracticeSelection
                                customerSkillPreference={customerSkillPreference}
                                getPracticeSession={getPracticeSession}
                                currentPracticeSessionInfo={
                                    {
                                        currentIndex: practiceSession.currentLessonIndex,
                                        schedule: practiceSession.schedule
                                    }}
                                resumePractice={resumePractice}
                            />
                        </div>
                    </div>
                </>
    )
}

export default LaunchOverview;
