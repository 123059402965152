import { getAuthToken, storeAuthToken, deleteAuthToken, getDecodedAuthToken } from './auth';
import { getSubscriptionStatus, updateJWT } from '../services/loginServiceGateway';

// updateJWTIfNecessary checks if the subscription status in the db in case in the JWT it is neither active nor trialing
// if the subscription status in the db is different from the one in the JWT, the JWT is updated
// user gets logged out if the JWT update fails 
export async function updateJWTIfNecessary() {
  const authToken = getAuthToken();
  const subscriptionStatusFromToken = authToken ? getDecodedAuthToken(authToken).subscription_status : null;

  if (subscriptionStatusFromToken !== 'active' && subscriptionStatusFromToken !== 'trialing') {
    let subscriptionStatusFromDb;

    try {
        console.log("get subscription status from db")
      subscriptionStatusFromDb = await getSubscriptionStatus(authToken);
    } catch (err) {
      if (subscriptionStatusFromDb && subscriptionStatusFromDb.status === 401) {
        throw new Error('User not authorized');
      }
    }

    if (subscriptionStatusFromDb !== subscriptionStatusFromToken) {
      try {
        const updatedJWT = await updateJWT(authToken);
        deleteAuthToken();

        if (updatedJWT) {
          storeAuthToken(updatedJWT.data);
        }
      } catch (err) {
        throw new Error('Updating JWT failed - user will be logged out.');
      }
    }
}
console.log("update not necessary")

}
