import React, { Component } from "react";
import "./lesson.css"
import { icons } from "../common/icons.jsx"
import { getMusicSheet } from "../../services/scheduleServiceGateway";
import { getAuthToken } from "../../util/auth";
import { saveAs } from 'file-saver'
import { VimeoOverlay } from "../vimeoOverlay.jsx";
import { isVimeoAllowed } from "../cookieHandler.jsx"
import Metronome from "./metronome";
import Stopwatch from "../stopwatch";
import { getVideoIFrame } from "../../services/vimeoGateway.js";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    // smooth not working with safari
    //behavior: "smooth"
  });
};

class Lesson extends Component {

  state = {
    checked: false,
    showMore: false,
    skipLesson: false,
    errors: { file: "" },
    //necessary to reset metronome
    metronomeKey: 0,
    iFrame: null
  }

  async componentDidMount() {
    scrollToTop();
    let iFrame = await getVideoIFrame(this.props.lesson?.video_url);
    this.setState({ iFrame: iFrame });
  }

  getIFrame() {
    return this.state.iFrame ? <div dangerouslySetInnerHTML={{ __html: this.state.iFrame }} /> : null;
  }

  async downloadFile() {
    let musicSheet;
    try {

      musicSheet = await getMusicSheet(getAuthToken(), this.props.lesson?.lesson_id);

      const blob = new Blob([musicSheet.data], { type: 'application/pdf' })
      saveAs(blob, this.props.lesson?.name + ".pdf");
    } catch (err) {
      this.setState({ errors: { file: "Could not retrieve music sheet. Please contact us: info@drumx.com" } })
      console.error(`could not retrieve music sheet: ${err}`);
    }
  }

  turnLessonCard = (event) => {
    scrollToTop();
    const checked = { checked: event.target.checked };
    this.setState(checked);
  }

  turnLessonCard2 = (event) => {
    scrollToTop();
    const checked = { checked: !this.state.checked };
    this.setState((prevState) => ({ metronomeKey: prevState.metronomeKey + 1 }))
    this.setState(checked);
    this.setState({ errors: { file: "" } });
  }

  handleFinishExercise = async (event) => {
    this.turnLessonCard2(event);
    this.setState((prevState) => ({ metronomeKey: prevState.metronomeKey + 1 }))
    this.resetSkipLesson();
    await this.props.finishExercise(this.state.skipLesson, this.props.ctx);
  }

  finishWarmUp = () => {
    scrollToTop();
    this.props.finishExercise(this.state.skipLesson, this.props.ctx);
    this.setState((prevState) => ({ metronomeKey: prevState.metronomeKey + 1 }))
    this.resetSkipLesson();
  }

  displayPadSufficient() {
    if (this.props.lesson?.pad_sufficient) {
      return icons.get("hand_technique_mini")
    }
  }
  makeTextUnfoldable(text) {
    if (!text || text.length < 400) {
      return (<p>{text}</p>);
    }
    const showText = this.state.showMore;
    return (
      <p>
        {this.state.showMore ? text : `${text.substring(0, 250)}...  `}
        <button id="btn-show-more" onClick={() => this.invertShowText(showText)}>{this.state.showMore ? "show less" : "show more"}</button>
      </p>)
  }

  numberLessonsIndicator(number, indexActive) {
    return (
      <div className="progress-schedule" style={{ display: "flex", justifyContent: "space-around" }}>{
        [...Array(number).keys()].map((e) => {
          if (e <= indexActive) {
            // todo: fix hardcoded 500 => makes issues regarding resizing (if window is smaller then 500px)
            return (<progress value="100" max="100" style={{ width: 500 / number }}></progress>)
          } else {
            return (<progress value="0" max="100" style={{ width: 500 / number }}></progress>)
          }
        })}
      </div>);
  }

  invertShowText(showBoolean) {
    this.setState({
      ...this.state.checked,
      showMore: !showBoolean
    })

  }

  handleSkipLessonCheckbox = (event) => {
    this.setState({
      skipLesson: event.target.checked
    });
  }

  resetSkipLesson = () => {
    this.setState({
      skipLesson: false
    })
  }



  render() {
    return (
      <div className="lesson" key={this.props.lesson.lesson_id}>

        <div className="lesson-card-page">
          <input
            type="checkbox"
            id="check-box-turn"
            name="check-box"
            checked={this.state.checked}
            readOnly
          />
          <label htmlFor="check-box">
            <div className="lesson-card-content">
              <div className="lesson-card-front">
                <div className="lesson-card-header">
                  <div className="lesson-card-icon" id="left">
                    {icons.get(this.props.lesson?.focus + "_mini")}
                  </div>
                  <div className="stopwatch">
                    <Stopwatch stopWatchTime={this.props.stopWatchTime} onTimeUpdate={this.props.onTimeUpdate} />
                    <div id="icon">
                      {icons.get("stopwatch_mini")}
                    </div>
                  </div>
                </div>
                <h1 className="card-title" >
                  {this.props.lesson?.name}
                </h1>
                <div className="card-front" id="front-id">
                  {/**conditional rendering necessary for mobile browser */}
                  {this.state.checked ? <div className="video">
                  </div> : <div className="video">
                    {isVimeoAllowed() ? this.getIFrame() : <VimeoOverlay />}
                  </div>}
                  {/* card info in div to align better? fit content? */}
                  <div className="lesson-card-info-container">
                    < div className="lesson-card-info" >
                      {icons.get("timing_mini")}
                      < p id="info-text" >
                        {this.props.lesson?.start_bpm}
                      </p >
                    </div >
                    <div className="lesson-card-info" >
                      {icons.get("stopwatch_mini")}
                      <p id="info-text">
                        {this.props.lesson?.practice_time} min</p>
                    </div>
                    <div className="lesson-card-info" >
                      <a href="#!" onClick={() => this.downloadFile()}>
                        {icons.get("download_sheet_mini")}
                      </a>
                      <a id="info-text" href="#!" onClick={() => this.downloadFile()}>
                        download sheet</a>
                      {this.state.errors.file && <div className="validation-error">{this.state.errors.file}</div>}
                    </div>
                    <div className="lesson-card-info" id="pad-icon">
                      {this.displayPadSufficient()}
                    </div>
                    <div className="lesson-card-info">
                      # {this.props.currentLessonNumber + 1} / {this.props.numberLessons}
                    </div>
                  </div>
                  <Metronome key={this.state.metronomeKey} bpm={this.props.lesson?.start_bpm} />
                  <div className="lesson-card-body">
                    {this.makeTextUnfoldable(this.props.lesson?.description_text)}
                  </div>
                </div>
                <div>
                  <button
                    id="btn-front"
                    onClick={this.props.currentLessonType == 'warm_up' ? this.finishWarmUp : this.turnLessonCard2}>
                    {this.props.currentLessonType == 'warm_up' ? 'warmed up' : 'evaluate lesson'}
                  </button>
                  {this.numberLessonsIndicator(this.props.numberLessons, this.props.currentLessonNumber)}
                </div>
                <div className="lesson-card-icon" id="right">
                  {icons.get(this.props.lesson?.focus + "_mini")}
                </div>
              </div >
              {/*Pointer events needed due to backface-visibility firefox bug*/}
              <div className="lesson-card-back" style={this.state.checked ? { pointerEvents: "unset" } : { pointerEvents: "none" }}>
                <h1>Hi {this.props.username}!</h1>
                <div className="lesson-card-back-text">
                  Please evaluate how difficult you've experienced this lesson.
                </div>
                <div className="rating">

                  <input
                    className="lesson-card-input"
                    id="rating"
                    type="range"
                    min="0"
                    max="4"
                    defaultValue="2"
                    onChange={input => { this.props.handleEvaluation(input) }}
                  />
                  <h3>{this.props.evaluation.rating_difficulty}</h3>
                </div>
                <div className="lesson-card-footer">
                  <div className="eval-button">

                    <button
                      id="btn-back"
                      onClick={this.turnLessonCard2}>
                      back to lesson
                    </button>
                    <button
                      id="btn-back"
                      onClick={this.handleFinishExercise}>
                      {this.props.currentLessonNumber >= (this.props.schedule.length - 1) ? 'finish practice' : 'evaluate lesson'}
                    </button>
                  </div>
                  {this.props.currentLessonType !== 'warm_up' ?
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          key={"skip-lesson-" + this.state.skipLesson}
                          defaultChecked={this.state.skipLesson}
                          onClick={(input) => this.handleSkipLessonCheckbox(input)} />
                        I have mastered this lesson.</label>
                      <p>(If you check this box the lesson will be marked as finished)</p>
                    </div> : null}
                </div>
                {this.numberLessonsIndicator(this.props.numberLessons, this.props.currentLessonNumber)}
              </div>
            </div >
          </label >
        </div>
      </div>
    )
  }
}


export default Lesson;