import React, { useState, useEffect, useRef } from 'react';
import './timePicker.css';

const TimePicker = () => {
  const [selectedTime, setSelectedTime] = useState(45);
  const timeSelectionRef = useRef(null);

  const timeOptions = [15, 20, 30, 45, 60, 90, 120, 180];

  const initialScrollValue = 4 * 16;

  useEffect(() => {
    if (timeSelectionRef.current) {
      timeSelectionRef.current.scrollTop = initialScrollValue;
    }
  }, []);


  const handleTimeSelection = (time) => {
    setSelectedTime(time);

    // Scroll the selected time into view
    const selectedElement = document.getElementById(`time-option-${time}`);
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  };


  return (
    <div className="time-picker">
      <div className="time-selection" ref={timeSelectionRef}>
        {timeOptions.map((time) => (
          <div
            key={time}
            id={`time-option-${time}`}
            className={`time-option ${selectedTime === time ? 'selected' : ''}`}
            onClick={() => handleTimeSelection(time)}
          >
            {time} min
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimePicker;
