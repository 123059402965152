import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import './cookieConsentPopUp.css'

import { icons } from "./common/icons";
import { setConsentCookies, isVimeoAllowed, isGoogleAnalyticsAllowed } from './cookieHandler';

export const CookieConsentPopUp = ({ showCookiePref, setShowCookiePref }) => {

  const REQUIRED = true;
  const [checkedVimeo, setCheckedVimeo] = useState(isVimeoAllowed());
  const [checkedGoogleAnalytics, setCheckedGoogleAnalytics] = useState(isGoogleAnalyticsAllowed());

  const closePopup = (timeout) => {
    setTimeout(() => {
      setShowCookiePref(false);
    }, timeout || 0);
    window.location.reload(false);
  }

  const closeExplicitly = () => {
    setConsentCookies(REQUIRED, REQUIRED, false, false);
    closePopup(0);
  }

  function acceptAllCookies() {
    setCheckedVimeo(true);
    setCheckedGoogleAnalytics(true);
    setConsentCookies(REQUIRED, REQUIRED, true, true);
    closePopup(300);
  }

  function declineAllCookies() {
    setConsentCookies(REQUIRED, REQUIRED, false, false);
    closePopup(0);
  }

  function acceptSelectedCookies() {
    setConsentCookies(REQUIRED, REQUIRED, checkedVimeo, checkedGoogleAnalytics);
    setShowCookiePref(false);
    closePopup(0);
  }

  const popupStyle = {
    maxHeight: "100vh",
    overflowY: "auto",
  }

  return (
    <div>
      <Popup open={showCookiePref} onClose={closePopup} contentStyle={popupStyle}>
        <div className="modal-cookie-consent" >
          <div className="consent-close">
            <button onClick={closeExplicitly}>
              &times;
            </button >
          </div>
          <h1>{icons.get("drumx_logo_fixed")}</h1>
          <h2>This website uses cookies</h2>
          <p>We use cookies to provide the best learning experience DrumX can offer.
          </p>
          <div className="consent-button" id="consent-button-mobile">
            <button id="consent-decline-all" onClick={declineAllCookies}>Decline Unrequired</button>
            <button id="consent-accept-selection" onClick={acceptSelectedCookies}>Accept Selection</button>
            <button id="consent-accept-all" onClick={acceptAllCookies}>Accept All</button>
          </div>
          <div id="consent-checkbox-container">

            <h3>🍪 Required Cookies - Mandatory   </h3> <label className="switch">
              <input type="checkbox" readOnly checked={REQUIRED} />
              <span className="slider round" id="required-consent-checkbox" ></span>
            </label>
          </div>
          <p>
            First Party cookie which is set during log-in to enable authentication and authorization.</p>
          <p>Third party cookies by <i>Stripe</i> for the payment process.
            <br />
            <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">Stripe's privacy policy</a>
            <br />
            <a href="https://stripe.com/en-de/legal/cookies-policy" target="_blank" rel="noopener noreferrer">Stripe's cookie policy</a></p>
          <br />
          <div id="consent-checkbox-container">

            <h3>🍪 Functional Cookies - Recommended  </h3> <label className="switch">
              <input type="checkbox" checked={checkedVimeo} onChange={e => setCheckedVimeo(e.target.checked)} />
              <span className="slider round"></span>
            </label>
          </div>
          <p>Third party cookies by <i>Vimeo</i> needed to use the full functionality of DrumX. All our video lessons are hosted by vimeo.
            <br /> <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">Vimeo's privacy policy</a>
            <br /> <a href="https://vimeo.com/cookie_policy" target="_blank" rel="noopener noreferrer">Vimeo's cookie policy</a>
          </p>
          <div id="consent-checkbox-container">

            <h3>🍪 Marketing Cookies - Optional  </h3> <label className="switch">
              <input type="checkbox" checked={checkedGoogleAnalytics} onChange={e => setCheckedGoogleAnalytics(e.target.checked)} />
              <span className="slider round"></span>
            </label>
          </div>
          <p>Third party cookies by <i>Google Analytics</i> needed to gain marketing insides. This cookie will help us to improve DrumX.
            <br /> <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">Google's privacy policy</a>
            <br /> <a href="https://policies.google.com/technologies/cookies?hl=de-DE" target="_blank" rel="noopener noreferrer">Google's cookie policy</a>
          </p>
          <div className="consent-button">
            <button id="consent-decline-all" onClick={declineAllCookies}>Decline Unrequired</button>
            <button id="consent-accept-selection" onClick={acceptSelectedCookies}>Accept Selection</button>
            <button id="consent-accept-all" onClick={acceptAllCookies}>Accept All</button>
          </div>
          <br />
          <div className="container">
            <p>You can change your settings later, as long as you are using the same device.</p>
            <div className="consent-footer">
              <a href="/privacy-policy">Privacy Policy</a>
              <a href="/impressum">Imprint</a></div>
          </div>
        </div>
      </Popup >

    </div>);
};