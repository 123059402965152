import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './checkoutForm';
import "./checkoutContainer.css"
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const CheckoutContainer = (props) => {

  const options = {
    clientSecret: props.clientSecret,

    // Fully customizable with appearance API.
    appearance: {
      theme: 'night',
      variables: {
        colorPrimary: '#ea631d',
        // See all possible variables below
      }
    },
  };

  const centsToEuro = (cents) => {
    return (cents / 100).toFixed(2);
  }

  return (
    <div className="checkout-body">
      <div className="form-container">
        <div className="billing-description">
          {props?.amount != 833 ?
            <div>          <p id="main-desc">
              Monthly subscription. Learn to play drums for only {centsToEuro(props?.amount)} €/Month (incl. VAT).
              The subscription is renewed each month.
            </p>
              <p id="addendum">
                You can cancel anytime until the end of the month.
              </p>
            </div>
            :
            <div>   <p id="main-desc">
              Holiday special! <sup>*</sup><br /> 3 months 30% off! <br />
              Learn to play drums for only <br/><s>11.90€/month</s>  {centsToEuro(props?.amount)} €/month (incl. VAT).
              The subscription is renewed each month.
            </p>
              <p id="addendum">
               <sup>*</sup>3 months for {centsToEuro(props?.amount)} €/month (incl. VAT), then 11.90€/month. 
              </p><p id="addendum">
                You can cancel anytime until the end of the month.
              </p>
            </div>
          }
        </div>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      </div>
    </div>
  );
}


export default CheckoutContainer;