import { useState, useEffect } from 'react';
import { getSkillPreference, getCustomerProgress, getCustomerPracticeActivity } from '../services/scheduleServiceGateway';

export function useCustomerSkillPreference(authToken) {
    const [customerSkillPreference, setCustomerSkillPreference] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchCustomerSkillPreference = async () => {
            try {
                const { data } = await getSkillPreference(authToken);
                if (!signal.aborted) {
                    setCustomerSkillPreference(data);
                }
            } catch (err) {
                console.error("Could not retrieve skill preferences", err);
            }
        };

        fetchCustomerSkillPreference();
        return () => {
            abortController.abort();
        }
    }, []);

    return customerSkillPreference;
}

export function useCustomerProgress(authToken) {
    const [customerProgress, setCustomerProgress] = useState([]);
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchCustomerProgress = async () => {
        try {
            const { data } = await getCustomerProgress(authToken);
            if (!signal.aborted) {
                setCustomerProgress(data);
            }
        } catch (err) {
            console.error("Could not retrieve customerProgress", err);
        }
    };


    useEffect(() => {
        fetchCustomerProgress();
        return () => {
            abortController.abort();
        }
    }, []);

    const refetchCustomerProgress = async () => {
        fetchCustomerProgress();
    };
    return { customerProgress, refetchCustomerProgress };
}


export function getPracticeActivity(authToken) {
    const [customerPracticeActivity, setCustomerPracticeActivity] = useState([]);
    const [activityCalendarLoading, setActivityCalendarLoading] = useState(true);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        async function fetchCustomerPracticeActivity() {
            try {
                const { data } = await getCustomerPracticeActivity(authToken);
                if (!signal.aborted) {
                    setCustomerPracticeActivity(data);
                    setActivityCalendarLoading(false);
                }
            } catch (err) {
                console.error("could not retrieve customer practice activity", err);
            }
        }

        fetchCustomerPracticeActivity();
        return () => {
            abortController.abort();
        }
    }, []);

    return { customerPracticeActivity, activityCalendarLoading };
}