import React, { useState, useEffect } from 'react';
import SkillAndLevelSelection from './registration/skillAndLevelSelection';
import "./registrationQuestionnaire.css";
import sheet from "../assets/sheet/reg-sheet.webp"
import soundTest from "../assets/soundfiles/reg-question-small.mp3"

const RegistrationQuestionnaire = ({getOverallProgressBar, ctx, skillSelfRating, updateLevel}) => {
  const [startQuestionnaire, setStartQuestionnaire] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [answers, setAnswers] = useState(['','','','']);


  const questions = [
    <div className="reg-question"> 
        <h1>Question 1</h1>
        <p>Have you ever played the drums?</p>
    </div>,
    <div className="reg-question">
        <h1>Question 2</h1>
        <p>Can you play a <em>paradiddle</em>?</p>
    </div>,
    <div className="reg-question">
        <h1>Question 3</h1>
        <p>Can you play songs you like by ear?</p>
    </div>,
    <div className="reg-question">
        <h1>Question 4</h1>
        <p>Can you play this independence exercise?</p>
        <img src={sheet} alt="sheet music"/>
        <audio controls>
          <source src={soundTest} type="audio/mpeg"></source>
        </audio>
    </div>
  ];

 const handleButton = (buttonDirection) => {
  if(buttonDirection === 'back' && currentQuestion === 0){
    setStartQuestionnaire(false);
    setAnswers(['','','','']);
    return;
  }
  
  if(buttonDirection === 'back' && currentQuestion > 0){
    setCurrentQuestion(currentQuestion - 1);
    setShowAdvancedSettings(false);
    return;
  }

  if(buttonDirection ==='next' && answers[currentQuestion] === 'no'){
    ctx.next();
    return;
  }
  
  if(buttonDirection ==='next' && currentQuestion===3){
    //setLevel(4);
    ctx.next();
    return;
  }
  if(buttonDirection ==='next'){
    //setLevel(currentQuestion + 1);
    setCurrentQuestion(currentQuestion + 1);
    return;
  }

  };

  const handleRadioButton = (answer, currentQuestion) => {
    
    if(answer === 'yes'){
      setLevel(currentQuestion + 1);
    } else {
      setLevel(currentQuestion);
    }
       // Create a copy of the current answers array
       const updatedAnswers = [...answers];
       // Update the specific question's value in the copy
       updatedAnswers[currentQuestion] = answer;
       // Set the updated array as the new state
       setAnswers(updatedAnswers);
  }

const setLevel = (currentQuestion) =>{
  if(currentQuestion === 0){
    setLevel1();
  }
  if(currentQuestion === 1){
    setLevel2();
  }
  if(currentQuestion === 2){
    setLevel3();
  }
  if(currentQuestion === 3){
    setLevel4_5();
  }
  if(currentQuestion === 4){
    setLevel6_7();
  }
}

  const setLevel1 =() =>{
    //console.log("setLevel1");
    updateLevel("beginner_course", 1);
    updateLevel("hand_technique", 1);
    updateLevel("hand_speed",0);
    updateLevel("independence", 1);
    updateLevel("groove_feel_sound", 0);
    updateLevel("timing", 0);
    updateLevel("drum_fills", 1);
    updateLevel("grooves", 0);
  }

  const setLevel2 =() =>{
    //console.log("setLevel2");
    updateLevel("beginner_course", 0);
    updateLevel("hand_technique", 2);
    updateLevel("hand_speed", 1);
    updateLevel("independence", 2);
    updateLevel("groove_feel_sound", 0);
    updateLevel("timing", 1);
    updateLevel("drum_fills", 2);
    updateLevel("grooves", 1);
    
  }
  
  const setLevel3 =() =>{
   // console.log("setLevel3")
    updateLevel("beginner_course", 0);
    updateLevel("hand_technique", 3);
    updateLevel("hand_speed", 2);
    updateLevel("independence", 3);
    updateLevel("groove_feel_sound", 2);
    updateLevel("timing", 2);
    updateLevel("drum_fills", 3);
    updateLevel("grooves", 2);
  }
  
  
  const setLevel4_5 =() =>{
    //console.log("setLevel4_5")
    updateLevel("beginner_course", 0);
    updateLevel("hand_technique", 4);
    updateLevel("hand_speed", 4);
    updateLevel("independence", 4);
    updateLevel("groove_feel_sound", 4);
    updateLevel("timing", 4);
    updateLevel("drum_fills", 4);
    updateLevel("grooves", 4);
  }
  
  const setLevel6_7 =() =>{
    //console.log("setLevel4")
    updateLevel("beginner_course", 0);
    updateLevel("hand_technique", 6);
    updateLevel("hand_speed", 6);
    updateLevel("independence", 6);
    updateLevel("groove_feel_sound", 6);
    updateLevel("timing", 6);
    updateLevel("drum_fills", 7);
    updateLevel("grooves", 7);
}

  return (
    <div className="reg-body">
      <div className="reg-questionnaire-container">
      {getOverallProgressBar(1)}
          <div className="reg-questionnaire">
        {startQuestionnaire === false ? 
         <div> 
            <div className="reg-question">
            <h1>Let's get started!</h1>
            <p>Help us to set up your drumming level.</p>
            <p>Just answer a few questions.</p>
            <p>No worries, the levels can be adjusted anytime later!</p>
          </div>
          <div className="reg-questionnaire-buttons">
            <button onClick={() => setStartQuestionnaire(true)}>
                Start quiz
            </button> 
          </div>
          <div className="questionnaire-info">(est. time: 1-2 minutes.)</div>

        </div>
        :
        <div className="reg-qbu">
          <div>{questions[currentQuestion]}</div>
          <div className="reg-questionnaire-radio">
            <label>
              <input
                type="radio"
                name="answer"
                checked={answers[currentQuestion] === 'yes'}
                onChange={() => handleRadioButton('yes', currentQuestion)}
                />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="answer"
                value="no"
                checked={answers[currentQuestion] === 'no'}
                onChange={
                  () => handleRadioButton('no', currentQuestion) }
                />
              No
            </label>
          </div>
          <div className="reg-questionnaire-buttons">
          <button onClick={() => handleButton('back')}>Back</button>
          <button disabled={answers[currentQuestion]===''} onClick={() => handleButton('next')}>Next</button>
        </div>
        </div>
        }
            <div className="advanced-button-div">
            {currentQuestion === 3 && answers[currentQuestion] === 'yes' ? 
            <button onClick={()=> {setShowAdvancedSettings(!showAdvancedSettings);}}>
              {showAdvancedSettings  ?  "hide advanced settings" : "show advanced settings" }
              </button> 
              : null}
              </div>
              
            </div>
            {showAdvancedSettings && answers[currentQuestion] === 'yes' ? <SkillAndLevelSelection skillSelfRating={skillSelfRating} updateLevel={updateLevel}/>:null}
            </div>
    </div>

  );
};

export default RegistrationQuestionnaire;
