import React, { Component } from "react";
import "./skillAndLevelSelection.css";
import { icons } from "../common/icons.jsx"
import { levelNames, skillDisplayName, levelDescriptionList, shortLevelDescription, shortSkillDescription } from "../common/globalStrings";


class SkillAndLevelSelection extends Component {
  state = {
    levelDescription: "you can click me as well :)"
  }

  getSelectedSkills() {
    return this.props.skillSelfRating
      .filter(elem => elem.selected === true)
      .map(elem => elem.skillName);
  }

  isCheckBoxActive(item) {
    var a = this.props.skillSelfRating
      .filter(elem => elem.skillName === item)
      .map(e => e.selected);
    return a[0];
  }

  isLevelSelectionIncomplete() {
    let selectedSkills = this.getSelectedSkills();
    return this.props.skillSelfRating
      .filter(elem => selectedSkills.includes(elem.skillName))
      .some(elem => elem.level === levelNames[0]);
  }

  getSelectedLevel(skill) {
    let lvl = this.props.skillSelfRating
      .filter(elem => elem.skillName === skill)
      .map(elem => elem.level);
    return levelNames.indexOf(lvl[0]);
  }
  getSelectedLevelName(skill) {
    return this.props.skillSelfRating
      .filter(elem => elem.skillName === skill)
      .map(elem => elem.level);
  }

  getLevelDescription(input) {
    const value = input.target.value
    switch (value) {
      case "0":
        this.setState({ levelDescription: levelDescriptionList[0] })
        break;
      case "1":
        this.setState({ levelDescription: levelDescriptionList[1] })
        break;
      case "2":
        this.setState({ levelDescription: levelDescriptionList[2] })
        break;
      case "3":
        this.setState({ levelDescription: levelDescriptionList[3] })
        break;
      case "4":
        this.setState({ levelDescription: levelDescriptionList[4] })
        break;
      case "5":
        this.setState({ levelDescription: levelDescriptionList[5] })
        break;
      case "6":
        this.setState({ levelDescription: levelDescriptionList[6] })
        break;
      case "7":
        this.setState({ levelDescription: levelDescriptionList[7] })
        break;
      default:
        return ("");
    }
  }


  displayLevel(skill) {
    let selectedLevel = this.getSelectedLevel(skill.skillName);
    if (selectedLevel <= 0) {
      return (<h2>Unselected</h2>);
    } 
    if (selectedLevel > 0) {
      return (<h2>Level: {selectedLevel}</h2>);
    }
  }

  displayShortLevelDescription(skill) {
    let selectedLevel = this.getSelectedLevel(skill.skillName);
    if (selectedLevel > 0) {
      return (<p>{shortLevelDescription[skill.skillName][selectedLevel - 1]}</p>);
    }
  }

  render() {
    return (
      < div className="skill-and-level-selection" >
        <h1 className="welcome-text-skill-selection">Feel free to fine tune <br/>the level selection!</h1>

        <div className="how-to-explanation">
          <div className="how-to">
            <div className="how-to-1">
            <h1>01</h1>
            <div className="how-to-content">
              <h2>Slide the dot</h2>
              <p>select your level</p>
            </div>
            </div>
            <div className="how-to-border"></div>
            <div className="how-to-2">
            <h1>02</h1>
            <div className="how-to-content">
              <h2>Click a card</h2>
              <p>to see more info</p>
            </div>
              </div>
          </div>
        </div>
        <div className="skill-pereference-outer-div">
          {[...this.props.skillSelfRating].map(skill => (
          <div>
            { /*hide beginner course in advanced settings*/ 
              skill.skillName === 'beginner_course' ? null :
            <div className="reg-card" key={skill.skillName}>
              <input className="check-box" type="checkbox" name={skill.skillName} id={skill.skillName} />
              <label htmlFor={skill.skillName} >
                <div className="reg-card-content">
                  <div className="reg-card-front" htmlFor="reg-card-content" id={skill.skillName + "_id"}>
                    <h1 className="reg-card-title"> {skillDisplayName[skill.skillName]}</h1>
                    <div className="reg-card-icon">
                    {icons.get(skill.skillName + "_mini")}
                    </div>
                    <input
                      className="reg-card-subtitle"
                      id={skill.skillName + "-level"}
                      type="range"
                      defaultValue={this.getSelectedLevel(skill.skillName)}
                      min="0"
                      max={shortLevelDescription[skill.skillName].length}
                      onChange={input =>
                        this.props.updateLevel(skill.skillName, input.target.value)} />
                    <div className="level-display">
                      {this.displayLevel(skill)}
                    </div>
                    <div className="level-desc">
                      {this.displayShortLevelDescription(skill)}
                    </div>
                    <div className="reg-card-back">
                      <p className="reg-card-body">{shortSkillDescription[skill.skillName]}</p>
                    </div>
                  </div>
                </div>
              </label>
            </div>
            }
            </div>
            
            ))}
        </div>
      </div >
    );
  }
}

export default SkillAndLevelSelection;
