import React, { Component } from "react";
import { changePassword } from "../../../services/loginServiceGateway";
import queryString from 'query-string';
import "./changePw.css"
import jwtDecode from "jwt-decode";
import { storeAuthToken } from "../../../util/auth";

const Joi = require("joi");
const { joiPasswordExtendCore } = require('joi-password');
const JoiPassword = Joi.extend(joiPasswordExtendCore);

const pwSchema = Joi.object({
  password: JoiPassword.string()
    .min(8)
    .minOfSpecialCharacters(1)
    .minOfLowercase(1)
    .minOfUppercase(1)
    .minOfNumeric(2)
    .noWhiteSpaces()
    .required(),
  secret: Joi.string().min(150).required()
});


class ChangePw extends Component {
  state = {
    changePwFormData: {
      password: "",
      secret: ""
    },
    formValidationError: "",
    firstname: ""
  }
  async componentDidMount() {

    console.log('mounted')
    const query = queryString.parse(this.props.location.search);
    const jwt = jwtDecode(query.secret)
    console.log('firstname: ', jwt.firstname)
    this.setState({ firstname: jwt.firstname })
    console.log('mounted query ', query)
    const secret = query.secret;
    if (secret) {
      const changePwFormData = { ...this.state.changePwFormData };
      changePwFormData["secret"] = secret;
      console.log("secret is there")
      this.setState({ changePwFormData });
    }
  }
  validate = () => {
    const { error } = pwSchema.validate(this.state.changePwFormData, { abortEarly: false });

    if (!error) return null;

    return error.details[0];
  };

  handleChange = ({ currentTarget: input }) => {
    const changePwFormData = { ...this.state.changePwFormData };
    changePwFormData[input.name] = input.value;
    this.setState({ changePwFormData });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const formValidationError = this.validate();
    this.setState({ formValidationError: formValidationError || {} });
    if (formValidationError) return;

    try {
      const response = await changePassword(this.state.changePwFormData.password, this.state.changePwFormData.secret);
      console.log("response", response)
      console.log("jwt", response.data)
      this.setState({ successMessage: "Password was updated, you will be logged in right away!" });
      storeAuthToken(response.data);
      this.props.history.push("/");
    }
    catch (error) {
      this.setState({ successMessage: "Something went wrong." });
    }
  };

  render() {
    return (
      <div className="change-pw-body">
        <div className="change-pw-card">
          <div className="change-pw-content">
            <h1>Hi {this.state.firstname}</h1>
            <p>You requested a password change.</p>
            <p>Just enter your new password below and hit the button.</p>
            <div className="change-pw-container">
              <form className="request-change-pw-mail-form" onSubmit={this.handleSubmit}>
                <label htmlFor="password">
                </label>
                <input
                  className="change-pw-input"
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                  value={this.state.changePwFormData.password}
                  error={this.state.formValidationError}
                  placeholder="New password" />
                <div id="change-pw-form-error">
                  {this.state.formValidationError.message && <div className="validation-error" >{this.state.formValidationError.message}</div>}
                </div>
                <div className="get-btn">
                  <button
                    className="change-pw-submit-btn"
                    type="submit"
                    disabled={!this.state.changePwFormData.password}
                  >Change Password</button>
                </div>
              </form>
              {this.state.successMessage && <div className="success-message">{this.state.successMessage}</div>}
            </div>

          </div>
        </div>
      </div >
    )
  }
}

export default ChangePw;