import React, { Component } from "react";
import { Wizard, Steps, Step } from "react-multistep-wizard";
import RegistrationForm from "./registrationForm";
import "./register.css";
import { register } from "../../services/loginServiceGateway";
import { postSkillPreference } from "../../services/scheduleServiceGateway";
import { storeAuthToken } from "../../util/auth";
import { getAuthToken } from "../../util/auth";
import { levelNames } from "../common/globalStrings";
import { getTermsAndConditionVersion } from "../termsAndConditions";
import {Helmet} from 'react-helmet-async';
import RegistrationQuestionnaire from "../registrationQuestionnaire";
import ReactGA from 'react-ga4';
import { isGoogleAnalyticsAllowed } from "../cookieHandler";

class Registration extends Component {
  state = {
    skillSelfRating: [
      { skillName: "beginner_course", selected: false, level: "level not set" },
      { skillName: "hand_technique", selected: false, level: "level not set" },
      { skillName: "hand_speed", selected: false, level: "level not set" },
      { skillName: "independence", selected: false, level: "level not set" },
      { skillName: "groove_feel_sound", selected: false, level: "level not set" },
      { skillName: "timing", selected: false, level: "level not set" },
      { skillName: "drum_fills", selected: false, level: "level not set" },
      { skillName: "grooves", selected: false, level: "level not set" }
      /*{ skillName: "foot_speed_and_technique", selected: false, level: "level not set" },
      { skillName: "Foot Speed And Technique", selected: false, level: "level not set" },
      { skillName: "Independence", selected: false, level: "level not set" },
      { skillName: "Soloing And Improvisation", selected: false, level: "level not set" },
      */
    ],
    registrationFormData: {
      email: "",
      country:"",
      password: "",
      firstname: "",
      lastname: ""
    },
    clientSecret: ""
  };

  componentDidMount() {
    if(isGoogleAnalyticsAllowed()){
      ReactGA.event('registration_started');
    }
  }

  updateSkillSelection = skill => {
    const currentSkills = [...this.state.skillSelfRating];

    const changedSkill = currentSkills.filter(elem => elem.skillName === skill);

    if (changedSkill.length > 0) {
      changedSkill[0].selected = !changedSkill[0].selected;

      /* if (changedSkill[0].selected === false) {
         changedSkill[0].level = "level not set";
       }*/
      this.setState({ changedSkill });
    }
    //console.log("current", this.state.skillSelfRating);
  };

  updateLevel(skill, level) {
    //console.log("update level", skill, level);
    const currentSkills = [...this.state.skillSelfRating];

    const changedSkill = currentSkills.filter(elem => elem.skillName === skill);

    if (changedSkill.length > 0) {
      changedSkill[0].level = levelNames[level];

      // set level to unselected as levelNames[0] = "level not set" in globalStrings (haha clean code mofo)
      if (changedSkill[0].level === levelNames[0]) {
        changedSkill[0].selected = false;

      } else {
        changedSkill[0].selected = true;
      }

      this.setState({ changedSkill });
    }
    //console.log("current", this.state.skillSelfRating);
  }

  handleRegistrationFormChange = ({currentTarget: input}) => {
    const registrationFormData = { ...this.state.registrationFormData };
    registrationFormData[input.name] = input.value;
    this.setState({ registrationFormData });
  };

  handleRegistrationFormCountrySelection = selection => {
    console.log('handleRegistrationFormCountrySelection', selection)
    const registrationFormData = { ...this.state.registrationFormData };
    registrationFormData.country = selection;
    this.setState({registrationFormData});
  }

  submitRegistration = async event => {
    event.preventDefault();
    console.log(this.state.registrationFormData)
    console.log("submitRegistration");
    try {
      const response = await register(this.state.registrationFormData, getTermsAndConditionVersion());
      if(isGoogleAnalyticsAllowed()){
        ReactGA.event('sign_up');
      }
      storeAuthToken(response.data.token);
      this.setState({ clientSecret: response.data.clientSecret });
      await postSkillPreference(getAuthToken(), this.state.skillSelfRating);
      //this.props.history.push("/launch-overview");
      window.location = "/launch-overview";
    }
    catch (error) {
      console.log(error.response.status);
      throw error;
    }
  };

  getOverallProgressBar(step) {
    return (
      <div className="stepper-wrapper">
        <div className={`stepper-item ${step <= 1 ? "active" : "completed"}`}>
          <div className="step-counter">1</div>
          <div className="step-name">Questionnaire</div>
        </div>
        <div className={`stepper-item ${step === 2 ? "active" : step > 2 ? "completed" : ""}`}>
          <div className="step-counter">2</div>
          <div className="step-name">Personal Data</div>
        </div>
        {/*<div className={`stepper-item ${step === 3 ? "active" : ""}`}>
          <div className="step-counter">3</div>
          <div className="step-name">PaymentInfo</div>
    </div>*/}
      </div>);

  }

  render() {
    return (
      <div>
        <Helmet>
          <title>DrumX - Registration</title>
          <meta name="description" content="DrumX - Registration. Join now for free. 2 weeks trial - then just 11.90€!" />
          <link rel="canonical" href="/register" />
        </Helmet>
        <Wizard>
          <Steps>
            <Step>
              {ctx => (
                  <RegistrationQuestionnaire
                    ctx={ctx} 
                    getOverallProgressBar={step => this.getOverallProgressBar(step)}
                    skillSelfRating={this.state.skillSelfRating}
                    updateLevel={(skill, level) =>
                      this.updateLevel(skill, level)
                    }
                  />
              )
              }
            </Step>
          {/*}  <Step>
              {ctx => (
                <div>
                 
                  <SkillAndLevelSelection
                    ctx={ctx}
                    getOverallProgressBar={step => this.getOverallProgressBar(step)}
                    updateSkillSelection={skill =>
                      this.updateSkillSelection(skill)
                    }
                    updateLevel={(skill, level) =>
                      this.updateLevel(skill, level)
                    }
                    skillSelfRating={this.state.skillSelfRating} />
                </div>
              )
              }
            </Step>//*/} 
            <Step>
              {ctx => (
                <div>
                  {/*wizardProgressBar("2")*/}
                  <RegistrationForm
                    ctx={ctx}
                    getOverallProgressBar={step => this.getOverallProgressBar(step)}
                    registrationFormData={this.state.registrationFormData}
                    handleRegistrationFormChange={this.handleRegistrationFormChange}
                    handleRegistrationFormCountrySelection={this.handleRegistrationFormCountrySelection}
                    submitRegistration={this.submitRegistration}
                  />
                </div>
              )}
            </Step>
           {/*} <Step>
              {ctx => (
                <div>
                  <React.Suspense fallback={<Loader />}>
                    <CheckoutContainer
                      getOverallProgressBar={step => this.getOverallProgressBar(step)}
                      ctx={ctx}
                      clientSecret={this.state.clientSecret}
                      submitRegistration={this.submitRegistration}
                    />
                  </React.Suspense>
                </div>
              )}
            </Step>//*/}
          </Steps>
        </Wizard>
      </div >
    );
  }
}

export default Registration;
