import React from "react";
import { ReactComponent as HandSpeedSVG } from '../../img/hand_speed.svg';
import { ReactComponent as HandTechniqueSVG } from '../../img/hand_technique.svg';
import { ReactComponent as IndependenceSVG } from '../../img/independence.svg';
import { ReactComponent as GrooveFeelAndSoundSVG } from '../../img/groove_feel.svg';
import { ReactComponent as TimingSVG } from '../../img/metronome.svg';
import { ReactComponent as DrumFillsSVG } from '../../img/drum_fills.svg';
import { ReactComponent as GroovesSVG } from '../../img/groove.svg';
import { ReactComponent as WarmUpSVG } from '../../img/warmup.svg';
import { ReactComponent as DownloadSheetSVG } from '../../img/sheet.svg';
import { ReactComponent as StopWatchSVG } from '../../img/stopwatch.svg';
import { ReactComponent as DrumXLogoSVG } from '../../img/drumx_logo.svg';
import { ReactComponent as DrumXLogoSVGFixed } from '../../img/drumx_logo_fixed.svg'
import { ReactComponent as BeginnerCourseSVG } from '../../img/beginner_course.svg';
import { ReactComponent as LevelUpSVG } from '../../img/level_up.svg';

export const icons = new Map([
  ["drumx_logo_fixed", <DrumXLogoSVGFixed id="logo_fixed" />],
  ["drumx_logo", <DrumXLogoSVG />],
  ["drumx_logo_mini", <DrumXLogoSVG id="mini" />],
  ["drumx_logo_mid", <DrumXLogoSVG id="mid" />],
  ["drumx_logo_mid_2", <DrumXLogoSVG id="mid_2" />],
  ["drumx_logo_big", <DrumXLogoSVGFixed id="big" />],
  ["drumx_logo_nav", <DrumXLogoSVGFixed id="logo-nav" />],
  ["hand_speed", <HandSpeedSVG />],
  ["hand_speed_mini", <HandSpeedSVG id="mini" />],
  ["hand_speed_mid", <HandSpeedSVG id="mid" />],
  ["hand_technique", <HandTechniqueSVG />],
  ["hand_technique_mini", <HandTechniqueSVG id="mini" />],
  ["hand_technique_mid", <HandTechniqueSVG id="mid" />],
  ["independence", <IndependenceSVG />],
  ["independence_mini", <IndependenceSVG id="mini" />],
  ["independence_mid", <IndependenceSVG id="mid" />],
  ["groove_feel_sound", <GrooveFeelAndSoundSVG />],
  ["groove_feel_sound_mini", <GrooveFeelAndSoundSVG id="mini" />],
  ["groove_feel_sound_mid", <GrooveFeelAndSoundSVG id="mid" />],
  ["timing", <TimingSVG />],
  ["timing_mini", <TimingSVG id="mini" />],
  ["timing_mid", <TimingSVG id="mid" />],
  ["drum_fills", <DrumFillsSVG />],
  ["drum_fills_mini", <DrumFillsSVG id="mini" />],
  ["drum_fills_mid", <DrumFillsSVG id="mid" />],
  ["grooves", <GroovesSVG />],
  ["grooves_mini", <GroovesSVG id="mini" />],
  ["grooves_mid", <GroovesSVG id="mid" />],
  ["warm_up", <WarmUpSVG />],
  ["warm_up_mini", <WarmUpSVG id="mini" />],
  ["warm_up_mid", <WarmUpSVG id="mid" />],
  ["download_sheet_mini", <DownloadSheetSVG id="mini" />],
  ["download_sheet_mid", <DownloadSheetSVG id="mid" />],
  ["stopwatch_mini", <StopWatchSVG id="mini" />],
  ["stopwatch_mid", <StopWatchSVG id="mid" />],
  ["beginner_course", <BeginnerCourseSVG />],
  ["beginner_course_mini", <BeginnerCourseSVG id="mini" />],
  ["beginner_course_mid", <BeginnerCourseSVG id="mid" />],
  ["level_up_mini", <LevelUpSVG id="mini" />],
]);
