import React from "react";
import './vimeoOverlay.css'
import { allowVimeo } from "./cookieHandler";

export const VimeoOverlay = () => {

  function getConsent() {
    allowVimeo();
    window.location.reload(false);
  }
  return (
    <div className="vimeo-overlay">
      <div className="textbox">

        <h1>Vimeo BLOCKED due to privacy settings</h1>
        <h2>Video from Vimeo has been blocked because you did not give your consent.</h2>
        <p>With accepting the use of vimeo, you will change your privacy settings. These can be adjusted anytime later (see cookie preferences in the footer).</p>
        <button className="vimeo-consent-button" onClick={getConsent}>Accept to load video</button>
        <div className="vimeo-footer">
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </div>
      </div>
    </div>
  )
}