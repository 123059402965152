import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import './checkoutForm.css';
import Loader from '../loader';

const RETURN_URL = process.env.REACT_APP_RETURN_URL;

//let isRegistered = false;
const CheckoutForm = () => {  
  const stripe = useStripe();
  const elements = useElements();
  
  const [errorMessage, setErrorMessage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
 
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setShowLoader(true);
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: RETURN_URL,
      },
    });


    if (error) {
      setShowLoader(false);
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)

      //todo make more readable
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    console.log("submit stripe form")
  };


  return (

    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {showLoader ? <Loader/>: null}
      <button disabled={!stripe}>Subscribe</button>
      {/* Show error message to your customers */}
      {errorMessage && <div className="checkout-error-message">{errorMessage}</div>}
    </form >
  )
};

export default CheckoutForm;