import Cookies from "universal-cookie";

const cookies = new Cookies();
const GDPR_COOKIE = "gdpr-cookie";
const options = { maxAge: 31536000 };

export function getGdprCookie() {
  return cookies.get(GDPR_COOKIE);
}

export function setInitialGdprCookie() {
  const initialCookie = JSON.parse(`{ "drumx":true, "stripe":true, "vimeo":false, "googleAnalytics":false, "consentSeen":false}`);
  cookies.set(GDPR_COOKIE, initialCookie, options);
}

export function isVimeoAllowed() {
  try {
    const gdprCookie = getGdprCookie();
    return gdprCookie.vimeo;
  } catch (err) {
    console.log('no initial cookie is set');
    return false;
  }
}

export function setConsentCookies(drumx, stripe, vimeo, googleAnalytics) {
  const consentCookies = JSON.parse(`{"drumx":${drumx}, "stripe":${stripe}, "vimeo":${vimeo}, "googleAnalytics":${googleAnalytics}, "consentSeen":true}`);
  cookies.set(GDPR_COOKIE, consentCookies, options);
}

export function allowVimeo() {
  const gdprCookie = getGdprCookie();
  gdprCookie.vimeo = true;
  cookies.set(GDPR_COOKIE, gdprCookie, options);
}

export function allowGoogleAnalytics() {
  const gdprCookie = getGdprCookie();
  gdprCookie.googleAnalytics = true;
  cookies.set(GDPR_COOKIE, gdprCookie, options);
}

export function isGoogleAnalyticsAllowed(){
  try {
    const gdprCookie = getGdprCookie();
    return gdprCookie.googleAnalytics || false;
  } catch (err) {
    console.log('no initial cookie is set');
    return false;
  }
}

export function cookieConsentDisplayed(){
  try {
    const gdprCookie = getGdprCookie();
    return gdprCookie.consentSeen;
  } catch (err) {
    console.log('cookie consent displayed no initial cookie is set');
    return false;
  }
}