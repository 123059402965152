import './hamburger.css';
export default function Hamburger({clicked}) {
  const hamburgerLineClass = clicked ? 'hamburger-line clicked' : 'hamburger-line';

  return (
  <>
      <div className="hamburger">
        <div className={`${hamburgerLineClass} burger1`}/>
        <div className={`${hamburgerLineClass} burger2`}/>
        <div className={`${hamburgerLineClass} burger3`}/>
    </div>
    </>
  )
}