import React, { useEffect, useState } from 'react';
import ProgressGauge from './progressGauge';
import { icons } from '../common/icons';
import { formatTimeSecToMin } from '../../util/formatTime'
import './practiceSummary.css';
export const PracticeSummary = ({ schedule, progress, updatedProgress, updateProgressState, totalPracticeTimeStopwatchSeconds, continueToOverview }) => {
    const [practicedSkills, setPracticedSkills] = useState([]);
    const [progressLocal, setProgressLocal] = useState(progress);
    const [showUpdatedProgress, setShowUpdatedProgress] = useState(false);
    useEffect(() => {
        const getPracticedSkills = () => {
            const skillsWtihoutWarmUp = schedule.filter((item) => item.focus !== 'warm_up');
            const skillsNoDuplicates = removeDuplicates(skillsWtihoutWarmUp.map((item) => item.focus));
            setPracticedSkills(skillsNoDuplicates);
        }
        getPracticedSkills();
    }, []);

    // bit complicated - level up updates the progress state in the background - so the progress state needs to be shown as the updated one
    const levelUpUpdateCustomerProgress = async () => {
        await updateProgressState();
        setShowUpdatedProgress(true);
    }

    const handleContinue = () => {
        continueToOverview();
    }

    const removeDuplicates = (arr) => {
        return Array.from(new Set(arr));
    }
    return (
        <div className="practice-summary-container">
            <div className="practice-summary">
                <div className="practice-summary-textbox">
                    <h1 className="practice-report-heading">Practice Report</h1>
                    <hr className="divider"></hr>
                    <h2 className="practice-report-sub-heading"> Accomplishments</h2>
                    <p>You completed {schedule?.length} lessons.</p>
                    <p>You have practiced for {formatTimeSecToMin(totalPracticeTimeStopwatchSeconds)} minutes.</p>

                    {practicedSkills.map((focus) => {
                        return (
                            <div className="progress-diff" key={`${focus}_container`}>
                                <ProgressGauge
                                    key={`${focus}_progressGauge_before`}
                                    skillPref={focus}
                                    icon={icons.get(focus + "_mini")}
                                    customerProgress={progressLocal}
                                    size={'small'}
                                    isClickableForLevelUp={false}
                                />
                                <p>{'⇨'}</p>
                                <ProgressGauge
                                    key={`${focus}_progressGauge_after`}
                                    skillPref={focus}
                                    icon={icons.get(focus + "_mini")}
                                    //todo here is the issue -> updateProgressState is updating the progress and not the updatedProgress
                                    customerProgress={showUpdatedProgress ? progress : updatedProgress}
                                    size={'small'}
                                    updateProgressState={levelUpUpdateCustomerProgress}
                                    isClickableForLevelUp={true}
                                />
                            </div>
                        )
                    }
                    )}
                </div>
                <button className="practice-summary-continue-btn" onClick={handleContinue}>continue</button>
            </div>
        </div>
    )
};