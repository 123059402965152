import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import CheckoutContainer from "./registration/checkoutContainer";
import { deleteAuthToken, getAuthToken, getDecodedAuthToken, isAuthCookieExpired } from "../util/auth";
import { createPaymentIntent, createCustomerPortalSession, retrieveApiPromotionCode } from "../services/loginServiceGateway";
import ReactGA from 'react-ga4';
import { isGoogleAnalyticsAllowed } from "./cookieHandler";
import './subscription.css'
import Loader from "./loader";

const Subscription = () => {
    const [clientSecret, setClientSecret] = useState("");
    const [requestSubscription, setRequestSubscription] = React.useState(false);
    const [manageSubscription, setManageSubscription] = React.useState(false);
    const [activeSubscriptionShowText, setActiveSubscriptionShowText] = React.useState(false);
    const [amount, setAmount] = useState(1190);



    const handleClientSecret = (paymentIntent) => {
        setClientSecret(paymentIntent);
    }

    useEffect(() => {
        if (getAuthToken() && isAuthCookieExpired()) {
            deleteAuthToken();
            window.location.href = "/";
            return; 
        }
        
        const requestCheckoutForm = async () => {
            let response;
            try {
                if (getDecodedAuthToken().subscription_status === "active") {
                    setActiveSubscriptionShowText(true);
                    return;
                }

                response = await createPaymentIntent(getAuthToken());
                //important to update the client secret first - and then set the requestSubscription to true
                handleClientSecret(response.data.clientSecret);
                setAmount(response.data.amount);
                setRequestSubscription(true);
                if (isGoogleAnalyticsAllowed()) {
                    ReactGA.event('subscription_checkout_started');
                }
            } catch (err) {
                // 409 means that the user already has one active subscription
                if (err.response.status === 409) {
                    // delete this as case the user already has a active subscription
                    //deleteAuthToken();
                    setActiveSubscriptionShowText(true);
                } else {
                    setManageSubscription(true);
                }
            }
        };
        handleClientSecret("");

        requestCheckoutForm();
    }, []);

    const getUsername = () => {
        let jwt = getAuthToken();
        const { firstname } = jwtDecode(jwt);
        return firstname;
    }

    const getSession = async () => {
        const response = await createCustomerPortalSession(getAuthToken());

        const body = response.data;
        window.location.href = body.url;
    }
  

    return (
        <div className="manage-subscription">
            <div className="spacer">

                <div className="manage-subscription-card">
                    <h1>Hi {getUsername()}</h1>
                    {!requestSubscription && !manageSubscription && !activeSubscriptionShowText ?
                        <div className="loading-subscription">
                            Checking subscription status...
                            <Loader />
                        </div> : null}
                    {requestSubscription ? <div>
                        <p>Your trial period expired. <br />Thank you for testing DrumX.
                            <br />Subscribe now to use all features of DrumX again!</p>
                        <CheckoutContainer 
                        clientSecret={clientSecret} 
                        amount={amount} 
                        />
                    </div>
                        : null}
                    {manageSubscription ?
                        <div>
                            <p>You do not have an active subscription. Renew your subscription to use DrumX again.</p>
                            <button className="manage-subscription-button" onClick={getSession}>Manage Subscription</button>
                        </div>
                        : null}
                    {activeSubscriptionShowText ?
                        <div>
                            <p>You already have an active subscription. <br />Thank you for using DrumX!</p>
                            <a href="/launch-overview">Go to Practice</a>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
};

export default Subscription;