import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import { getAuthToken, deleteAuthToken, isAuthCookieExpired } from "./../util/auth";
import './accountSettings.css'
import { getCustomerInfo, cancelSubscription, createCustomerPortalSession, deleteCustomer } from "../services/loginServiceGateway";
import { deleteCustomerData } from "../services/scheduleServiceGateway";

class accountSettings extends Component {

  state = {
    accountData: "",
    cancelSubscriptionCheckbox: false,
    phrase: "",
    errorMessage: "",
    practiceActivity: [],
  };

  async componentDidMount() {
    if (getAuthToken() && isAuthCookieExpired()) {
      deleteAuthToken();
      this.props.history.push("/");
      return;
    }
    // todo error handling
    try {
      const { data } = await getCustomerInfo(getAuthToken());
      this.setState({ accountData: data })
    } catch (err) {
      console.log('Could not retrieve customer info', err);
    }
  }

  checkEmailVerification() {
    if (!this.state.emailVerificationState) {
      return (<p>please verify your email: <a href="/verify-email">Request email verification</a></p>);
    }
    return (<p>Your email is verified</p>);
  }

  async cancelSubscription() {
    console.log("cancel")
    try {
      await cancelSubscription(getAuthToken());

      window.location.reload(false);
    } catch (err) {
      console.log(err);
    }
  }

  handleChange = (event) => {
    const checked = { cancelSubscriptionCheckbox: event.target.checked }
    this.setState(checked);
  }
  getUsername() {
    let jwt = getAuthToken();
    const { firstname } = jwtDecode(jwt);
    return firstname;
  }
  showRemainingTime() {
    var date = new Date(this.state.accountData.cancelAt * 1000).toLocaleDateString(navigator.language || navigator.userLanguage)

    return (<p>You can use DrumX until<br /> {date}</p>)

  }
  handleInputField = (event) => {
    this.setState({ phrase: event.target.value });
  };

  async handleDeletionRequest() {
    if (this.state.phrase === "delete me") {
      this.setState({ errorMessage: "" })

      try {
        await deleteCustomerData(getAuthToken());
        await deleteCustomer(getAuthToken());
        deleteAuthToken();
        window.location = "/log-out";
        // todo history
        return;
      } catch (err) {
        console.log(`deletion failed: ${err}`);
        this.setState({ errorMessag: "An unexpected error occurred: please try again later or contact us per e-mail" })
      }
    }
    this.setState({ errorMessage: "To delete enter: delete me" })
  }

  async getSession() {
    const response = await createCustomerPortalSession(getAuthToken());
    const body = response.data;
    window.location.href = body.url;
  }

  render() {
    return (
      <div className="account-settings">
        <div className="wrapper">
          <input className="radio" id="account" name="group" type="radio" defaultChecked />
          <input className="radio" id="subscription" name="group" type="radio" />
          <input className="radio" id="deletion" name="group" type="radio" />
          <div className="tabs">
            <label className="tab" id="account-tab" htmlFor="account">Account</label>
            <label className="tab" id="subscription-tab" htmlFor="subscription">Subscription</label>
            <label className="tab" id="deletion-tab" htmlFor="deletion">Delete your account</label>
          </div>
          <div className="panels">
            <div className="panel" id="account-panel">
              <div className="panel-title">Account Information</div>
              <h3>General Information</h3>
              <p>Name: {this.state.accountData.firstname} {this.state.accountData.lastname}</p>
              <p>Email: {this.state.accountData.email}</p>
              <br />
              <h3>Change your password</h3>
              <a id="pw-change-link" href="/request-password-change"> Request password change</a>

            </div>

            <div className="panel" id="subscription-panel">
              <div className="panel-title">Subscription</div>
              <p>Subscription status: {this.state.accountData.status}</p>
              <button onClick={this.getSession}> Manage your subscription</button>
              {!this.state.accountData.cancelAtPeriodEnd && this.state.accountData.status === 'active' ? <div> <h4>Cancel Subscription</h4><p>You can cancel your subscription here. </p>
                <p>You will be able to use DrumX until the end of your billing period.</p>
                <div>
                  <input type="checkbox" defaultChecked={this.state.cancelSubscriptionCheckbox} onClick={this.handleChange} /> Check this box and click the button below to cancel subscription
                </div>
                <button disabled={!this.state.cancelSubscriptionCheckbox || this.state.accountData.cancelAtPeriodEnd} onClick={() => this.cancelSubscription()}>Cancel your subscription</button>
              </div> : this.state.accountData.cancelAtPeriodEnd ? <div>Your subscription has been canceled. {this.showRemainingTime()} </div> : null
              }
            </div>


            <div className="panel" id="deletion-panel">
              <div className="panel-title">Delete your Account</div>
              <p>You can delete your account here.</p>
              <p>If you delete your account, an active subscription will be canceled and all your data (including your progress) will be deleted. </p>
              <p>We do not provide a refund for remaining subscription time. If you just want to manage your subscription, please go to the tab 'Subscription'.</p>
              <div>
                Please enter the phrase - "delete me" - and then hit the delete button.
              </div>
              <br />
              <div>

                <input
                  placeholder="enter the phrase here"
                  type="text" value={this.state.phrase} onChange={this.handleInputField} />
                {this.state.errorMessage ? <div>{this.state.errorMessage}</div> : null}
              </div>
              <div>
                <br />
                <button onClick={() => this.handleDeletionRequest()}>Delete Your Account</button>
              </div>

            </div>
            <p>If you have any questions send us an email to <a id="contact-email-address" href="mailto:info@drumx.com" target="_blank" rel="noopener noreferrer">info@drumx.com</a> .</p>

          </div>
        </div>
      </div>)
  };
}


export default accountSettings;