import React, { useState } from 'react';
import './practiceSelection.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TimePicker from './timePicker';
import { skillDisplayName } from '../common/globalStrings';

function PracticeSelection({ customerSkillPreference, getPracticeSession, currentPracticeSessionInfo, resumePractice }) {
    const [selectedItems, setSelectedItems] = useState([]);
    const [practiceSelectionError, setPracticeSelectionError] = useState(null);
    const [time, setTime] = useState(0);
    const settings = {
        arrows: false,
        infinite: false,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        responsive: [
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };

    const toggleItemSelection = (itemId) => {
        setSelectedItems((prevSelected) => {
            if (prevSelected.includes(itemId)) {
                return prevSelected.filter((id) => id !== itemId);
            } else {
                return [...prevSelected, itemId];
            }
        });
    };

    const startPractice = async (isPadOnly) => {
        if (time < 10 || time > 360) {
            setPracticeSelectionError({ message: "Practice time must be between 10 and 360 minutes" });
            return;
        }
        if (selectedItems.length === 0) {
            setPracticeSelectionError({ message: "Please select at least one skill" });
            return;
        }
        setPracticeSelectionError(null);
        try {
            await getPracticeSession(selectedItems, time, isPadOnly);
        } catch (err) {
            setPracticeSelectionError({ message: "Something went wrong. Please try again later. If this error continues to exist, please contact us!" })
            console.log("error in getPracticeSession", err);
        }
    }

    const handleTimeChange = ({ currentTarget: input }) => {

        setTime(input.value);
    }

    const getRecommendedLessons = (isPadOnly) => {
        console.log("start lesson")
    }


    return (
        <Slider {...settings}>
            {currentPracticeSessionInfo.currentIndex <= currentPracticeSessionInfo?.schedule?.length
                && currentPracticeSessionInfo?.schedule?.[0].lesson_id !== 0
                ?
                <div className="lesson-picker-item">
                    <h3>Resume Practice</h3>
                    <div className="current-practice-infos">
                        <p>You have {currentPracticeSessionInfo.schedule.length - currentPracticeSessionInfo.currentIndex} lessons left.</p>
                    </div>
                    <div className="select-hardware-btn">
                        <button id="start-practice-btn" onClick={() => resumePractice()}>Resume Practice</button>

                    </div>
                </div>
                :
                null}
            <div className="lesson-picker-item">
                <h3>Customize your practice</h3>
                <div className="item-container">
                    {customerSkillPreference.map((item) => (
                        <div
                            key={item.focus}
                            className={`item-box ${selectedItems.includes(item.focus) ? 'selected' : ''}`}
                            onClick={() => toggleItemSelection(item.focus)}
                        >
                            {skillDisplayName[item.focus]}
                        </div>
                    ))}
                </div>
                <div className="time-input-manual">
                    <input
                        id="practice-time"
                        type="number"
                        key="practice-time"
                        onChange={handleTimeChange}
                        placeholder="practice time"
                        min="5"
                        max="360"
                        step="5"
                    />
                    <label htmlFor="practice-time">min</label>
                </div>
                {practiceSelectionError ? <div className="validation-error">{practiceSelectionError.message}</div> : null}

                <div className="select-hardware-btn">
                    <button id="start-practice-btn" onClick={() => startPractice(false)}>Drum Kit</button>

                    <button id="start-practice-btn" onClick={() => startPractice(true)}>Drum Pad</button>
                </div>

            </div>
            <div className="lesson-picker-item">

                <TimePicker />
                <h3>Recommended lessons</h3>
                <div className="not-available">coming soon!</div>
                <div className="select-hardware-btn">
                    <button disabled id="start-practice-btn" onClick={() => getRecommendedLessons(false)}>Drum Kit</button>
                    <button disabled id="start-practice-btn" onClick={() => getRecommendedLessons(true)}>Drum Pad</button>
                </div>
            </div>
        </Slider>
    );
}

export default PracticeSelection;