import React from 'react';
import { ReactComponent as InstagramSVG } from './../img/instagram.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./testimonialCarousel.css";
const testimonials = [
  {
    name: 'Fabian',
    userSince: 'Februrary 2023 ',
    message: '"While I have been playing guitar for over fifteen years, I have always been interested in drumming. The unique approach of this app finally helped me take the plunge: whenever I have some free time, I can do as many exercises as I want, tailored to my level - and at a fraction of the cost of a personal teacher. I have made great progress, even with irregular practice times, and would recommend it to anyone."',
  },
  {
    name: 'Leon',
    drummerOf: 'Unprocessed',
    userSince: 'October 2023',
    message: '"DrumX is a gamechanger when it comes to my practicing. Instead of wasting my time thinking about what i want to work on, DrumX does the thinking for me and i can start right away. On top of that it keeps track of my progress maximising my productive time!"',
    instagramPersonal: 'https://www.instagram.com/leon_m_pfeifer',
    instagramBand: 'https://www.instagram.com/unprocessedband'
  },
  ,
  {
    name: 'Veit',
    userSince:'July 2023',
    message:[`\"I\'m a huge fan of DrumX because I can practise with it at any time of the day or night. Whether it\'s with the practice pad or on the drum set in my studio or rehearsal room.`, 
    <br/>, 
    `Flexibility is the most important thing for me, as my daily schedule always changes between work, touring and studio jobs. With my laptop, I always have my drum workouts with me and if I don\`t have the internet, I save the sheets on my cell phone and practice with the cell phone metronome.`,
    <br/>,
    `I can also recommend DrumX as an experienced rock drummer, as there really are so many exercises and new lessons all the time that I never get bored. Check it out and try the 2 trial weeks.\"`],
    instagramPersonal:'https://www.instagram.com/veitschlembach'
  },
  {
    pseudoName: 'YOUR TESTIMONIAL HERE',
    name: 'DrumX',
    drummerOf: 'DrumX',
    userSince: '???',
    message: '"You are a user and want to share your experience with DrumX? Send us a message on Instagram or via email and we will add your testimonial here!"',
    instagramPersonal: 'https://www.instagram.com/drumxschool',
    instagramBand: 'https://www.instagram.com/drumxschool'
  }
  // Add more testimonials here
];

function TestimonialCarousel() {
  const settings = {
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      {testimonials.map((testimonial, index) => (
        <div key={index} className="testimonial-item">
          <h3>{testimonial.pseudoName ? testimonial.pseudoName : testimonial.name}</h3>
          <p id="user-since">DrumX user since {testimonial.userSince}</p>
          {testimonial.drummerOf ? <p id="drummer-of">Drummer of {testimonial.drummerOf}</p> : null}
          <p className="testimonial-message">{testimonial.message}</p>
          {testimonial.instagramBand || testimonial.instagramPersonal ? <div className="testimonial-links">
            {testimonial.instagramPersonal ?
              <a href={testimonial.instagramPersonal} target="_blank" rel="noopener noreferrer">
                <InstagramSVG className="icon" />
                {testimonial.name}`s Instagram
              </a> : null}
            <br />
            {testimonial.instagramBand ?
              <a href={testimonial.instagramBand} target="_blank" rel="noopener noreferrer">
                <InstagramSVG className="icon" />{testimonial.drummerOf}`s Instagram
              </a> : null}
          </div> : null}
        </div>
      ))}
    </Slider>
  );
}

export default TestimonialCarousel;
