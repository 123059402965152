import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from 'react-ga4';
import { getAuthToken, getDecodedAuthToken } from "./util/auth";
import { isGoogleAnalyticsAllowed } from './components/cookieHandler';


// if GA is initizialized here, it will be available throughout the app
if (process.env.REACT_APP_ENVIRONMENT === "PROD" && isGoogleAnalyticsAllowed()) {
  ReactGA.initialize([
    {
      trackingId: 'G-KC2XRLKDHZ',
      gaOptions: {
        anonymizeIp: true,
        allowAdFeatures: false,
        allowAdPersonalizationSignals: false,
      },
    },
  ])
}

if (getAuthToken()) {
  ReactGA.set({ userId: getDecodedAuthToken().customerId });
}


ReactDOM.render(
  <BrowserRouter>
    <HelmetProvider>
      <StrictMode>
        <App />
      </StrictMode>
    </HelmetProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
