import React, { useState } from 'react';
import './faqItem.css';

const FAQ = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <div className="faq" onClick={toggleAnswer}>
      {showAnswer ?    <div className="toggle-faq" onClick={toggleAnswer}>X</div> : <div className="toggle-faq" onClick={toggleAnswer}>&#x25BC;</div>}
      <p className="question" >
       {showAnswer? "🤓 ":"🤔 "}{question}
      </p>
      {showAnswer && <div className="answer" onClick={toggleAnswer}>{answer}</div>}
    </div>
  );
};

export default FAQ;