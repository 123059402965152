import React, { useEffect }from "react";
import "./home.css";
import { icons } from "./common/icons";
import {Helmet} from 'react-helmet-async';
import { isGoogleAnalyticsAllowed } from './cookieHandler';
import ReactGA from 'react-ga4';
import studio_snare_closeup from '../assets/img/leander_landing_1.webp';
import FAQItem from "./faqItem";
import TestimonialCarousel from "./testimonialCarousel";
const Home = () => {

  useEffect(() => {
    if(isGoogleAnalyticsAllowed()){
      ReactGA.event('landing_page_visited');
    }
  },[]);

  return (
    <div className="home">
    <Helmet>
      <title>DrumX - become the best Drummer you can be!</title>
      <meta name="description" content="DrumX accelerates your growth as a drummer. Join now for online drum lessons! 14 days trial - no risks (besides improved drumming)." />
      <link rel="canonical" href="https://drumx.com" />
    </Helmet>
      <div className="first-stripe">
        <div className="beta-phase-banner">Beta Phase</div>
        <div className="gradient-overlay"></div>
        <div className="main-image-div">
        <img className="main-image" src={studio_snare_closeup} alt="studio snare closeup" />
        </div>
        <div className="main-text">
          <h1 id="main-logo">{icons.get("drumx_logo_big")}</h1>
          <h2 className="sub-title" id="sub-title">Your drumming <br/>practice guide</h2>
          <h3 className="sub-sub-title">simple | tailored | fast </h3>
          <div id="join-button-container">
            <a className="join-button" id="join-button" href="/register">Sign Up For Free</a>
            </div>
        </div>
  </div>

   
   <div className="second-stripe-container">

    <div className="info">
      <h1>5 Reasons to join DrumX</h1>
        <ul>
          <li>Tailored Guidance Throughout Your Drumming Journey</li>
          <li>Experience a Consistent, Personalized Learning Path for Your Level, Progress, and Needs</li>
          <li>Put an End to the YouTube Lesson Hunt</li>
          <li>Optimize and track your progress</li>
          <li>Access Expert-Level Instructions for All Lessons</li>
        </ul>
    </div>
    <div className="pricing info">
         <h1>Pricing</h1>
      <div className="join-button-container">
            </div>
            <p className="price-mini-font"><s>€17,99 per month</s></p>
            <div>€11.90</div>
            <div className="price-mini-font">per month</div>
            <div className="join-button-container">
            <a className="join-button" id="join-button" href="/register">Free for 14 days</a>
      </div>
          <div className="pricing-list">
            <ul>
              <li>Free for the first 14 days</li>
              <li>Completely risk free</li>
              <li>No credit card needed for sign-up</li>
              <li>Cancel Anytime</li>
            </ul>
          </div>

      </div>
    <div className="teaching-philosophy info">
      <h1>Teaching Philosophy</h1>
        <ul>
          <li>Personalized Learning: We recognize that everyone learns differently.</li>
          <li>Customized Goals: Your drumming aspirations are unique.</li>
          <li>Varied Resources: Whether you have time, drums, or a practice pad, we adapt.</li>
        </ul>
        <p>That is why we don't offer standardized lessons packs or courses.<br/> You get lessons chosen for your needs that works for your current YOU!</p>
      </div>
      </div>

      <div className="testimonial-carousel-container">
        <h1>What our students say</h1>
        <TestimonialCarousel />
      </div>


  <div className="faq-container">
        <h1>Frequently Asked Questions</h1>
    <FAQItem 
    question={"What qualifies Leander of DrumX to be your drum instructor?"} 
    answer={"Leander, has learned how to play drums by the best drummers in the world (including Anika Niles and Jost Nickel). He turned pro by the age of 16, got his first endorsements (Zidljian, Vic Firth, Aquarian) by the age of 18 and is currently touring with multiple artists. He learned drums the same way that the DrumX system is teaching you to play drums."}
    />
    <FAQItem 
    question={"Is the 14-day trial completely free?"} 
    answer={"Yes. You don’t even have to give us your payment information. If you subscribe to DrumX and forget about it for a year, there will be no costs for you."}
    />
    <FAQItem 
    question={"What is a beta phase?"} 
    answer={"We are a small start-up with a big vision of making a better product for our students on a daily basis. In order to make sure that we are moving in the right direction, we are testing out different features and options. That\'s why we also offer our early adopters a special discount."}
    />
    <FAQItem 
    question={"Do I get access to all the DrumX features for just 11,90€ a month?"} 
    answer={"Yes. More than 9.000 exercises are waiting for you."}
    />
    <FAQItem 
    question={"Can I still use DrumX with only a practice pad or an electronic drum kit?"} 
    answer={"Of course. Before each practice session, you can select a “pad only” button. You will then receive lessons that work on a practice pad only. If you have no acoustic but an electronic drum set - that is perfect. You can use DrumX to the full extent."}
    />
    <FAQItem 
    question={"How can I determine my level?"} 
    answer={"During your registration, you will answer a few questions about your drumming experience. Based on your answers, we will determine your level. You can change your level at any time in your account settings later."}
    />
    <FAQItem 
    question={"What are Skill Preferences?"} 
    answer={"We divided our >9.000 exercises into groups like “Hand Technique, Drum Fills, Independence, Timing…”. These are our skill preferences."}
    />
    <FAQItem 
    question={"How do I cancel my subscription?"} 
    answer={"Easy. You log into your account. Press ”Settings” -> “Subscription” -> check the box -> “Cancel your subscription”"}
    />
     <FAQItem 
    question={"My question is not listed here. What can I do?"} 
    answer={`Just send us an email to info@drumx.com. We will get back to you as soon as possible.`}
    />
    </div>
  </div >
  );
};

export default Home;
