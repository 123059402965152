import http from "./rest";

const config = {
  headers: {
    "Content-Type": "application/json"
  }
};


const loginServiceEndpoint = process.env.REACT_APP_LOGINSERVICE_URL;

// why is this synchronous?
const login = (email, password) => {

  const payload = {
    email: email,
    password: password
  };
  // todo: error handling???
  return http.post(loginServiceEndpoint + "/api/authenticate", payload, config);
};

const register = (regForm, dateTermsAndConditions) => {
  const payload = {
    email: regForm.email,
    password: regForm.password,
    firstname: regForm.firstname,
    lastname: regForm.lastname,
    country: regForm.country,
    dateTermsAndConditions: dateTermsAndConditions
  };
  return http.post(loginServiceEndpoint + "/api/register", payload, config);
};

async function requestPwRecMail(email) {

  const config = {
    headers: {
    },
    params: {
      email: email
    }
  }

  try {
    return await http.get(loginServiceEndpoint + '/api/pw-recovery', config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function changePassword(password, secret) {

  const payload = {
    secret: secret,
    password: password
  };
  try {

    return http.post(loginServiceEndpoint + "/api/pw-recovery/change-password", payload, config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function verifyMail(secret) {
  const config = {
    headers: {
    },
    params: {
      secret: secret
    }
  }

  try {
    return await http.get(loginServiceEndpoint + '/api/verify/email', config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function requestVerificationMail(email) {
  const config = {
    headers: {
    },
    params: {
      email: email
    }
  }

  try {
    return await http.get(loginServiceEndpoint + '/api/verify/email/request-verification', config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function updateJWT(authToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.get(loginServiceEndpoint + '/api/authenticate/update-jwt', config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}
async function createCustomerPortalSession(authToken) {
  console.log("authToken: " + authToken)
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.get(loginServiceEndpoint + '/api/payment/create-customer-portal-session', config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function createPaymentIntent(authToken, promoApiCode) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  
  try {
    return await http.get(loginServiceEndpoint + '/api/payment/create-payment-intent', config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}


async function cancelSubscription(authToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.get(loginServiceEndpoint + '/api/payment/cancel-subscription', config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function getCustomerInfo(authToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.get(loginServiceEndpoint + '/api/customer-info', config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function getSubscriptionStatus(authToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.get(loginServiceEndpoint + '/api/customer-info/subscription-status', config);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function deleteCustomer(authToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.delete(loginServiceEndpoint + '/api/delete/account', config);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

const loginBetaAuth = (pw) => {
  const payload = {
    pw
  }
  return http.post(loginServiceEndpoint + "/api/authenticate/login", payload, config);
}

export { 
  login, 
  register, 
  loginBetaAuth, 
  verifyMail, 
  requestVerificationMail, 
  requestPwRecMail, 
  changePassword, 
  updateJWT, 
  cancelSubscription, 
  getCustomerInfo,
  getSubscriptionStatus,
  deleteCustomer, 
  createPaymentIntent,
  createCustomerPortalSession };
