
export const skillDisplayName =
{
  "hand_technique": "Hand Technique",
  "hand_speed": "Hand Speed",
  "independence": "Independence",
  "groove_feel_sound": "Groove, Feel and Sound",
  "timing": "Timing",
  "drum_fills": "Drum Fills",
  "grooves": "Grooves",
  "warm_up": "Warm Up",
  "beginner_course": "Beginner Course"
}

export const shortLevelDescription = {
  beginner_course: ["learn the basics"],
  hand_technique: ["never held sticks before", "able to play single strokes", "able to play paradiddles", "able to play rudiments in triplets", "able to play 5 & 7 stroke patterns", "able to play in quintuplets", "hardcore stuff"],
  hand_speed: ["never worked on speed before", "16th note singles at 70", "16th note singles at 110", "16th note singles at 150", "16th note singles at 180", "16th note singles at 200", "16th note singles at 230"],
  independence: ["never worked on independence", "switch between 8th and 16th notes", "switch between 16th notes and triplets", "mix quarter notes with triplets", "play rudiments over a foot ostinato", "able to solo over foot ostinati", "hardcore stuff"],
  groove_feel_sound: ["never held sticks before", "able to play single strokes", "able to play paradiddles", "able to play rudiments in triplets", "able to play 5 & 7 stroke patterns", "able to play in quintuplets", "hardcore stuff"],
  timing: ["what is a metronome", "able to play to a click", "able to play to a very slow click", "able to play to a 2 bar gap click", "able to play to a 4 bar gap click", "able to play to a off beat gap click", "hardcore stuff"],
  drum_fills: ["never worked on drum fills before", "able to play a few fills", "able to play improvised fills", "able to play triplet grooves", "able to play fills including kicks", "able to play fills in odd meters", "able to play fills in quintuplets"],
  grooves: ["never held sticks before", "able to play 8th note grooves", "able to play 16th note grooves", "able to play shuffle grooves", "feel comfortable in odd meters", "able to displace grooves", "hardcore stuff"],
  warm_up: ["never held sticks before", "able to play single strokes", "able to play paradiddles", "able to play rudiments in triplets", "able to play 5 & 7 stroke patterns", "able to play in quintuplets", "-"],
}

export const levelNames = [
  "level not set",
  "starter",
  "beginner",
  "advanced_beginner",
  "medium_advanced",
  "advanced",
  "pro",
  "absolute_pro"
];

export const skillDescription =
{
  "hand_technique": "Hand Technique ...might sound boring to some of you. But without a proper technique and some rudiments in the pocket, it gets pretty tough playing solos, grooves and fills.This course will take you through rudiments, accents placements, and much more, which are all practicable to use on the drum set as well.",
  "hand_speed": "Who doesn't want to have fast hands?! Even though being fast isn’t the most necessary skill a drummer can have, but it’s definitely fun and opens up a lot of doors. Building up speed takes time and consistency. But with the right exercises and with the right dose, it is no rocket science.",
  "independence": "Drumming sometimes requires playing four completely different things with four completely different limbs. So it’s not a bad idea to get them as independent from each other as possible. In case you are not Thomas Lang or Claus Heßler, total independence might be unachievable. This course will take you as far as you can and as far as you want. Let’s get into the fun stuff...",
  "groove_feel_sound": "Every professional drummer will tell you something like this. “Groove, feel, sound, and timing are by far the most important things a drummer should master.” Speed, fills, and independence is fun and also vital for us drummers. But really, where do crazy fills and solos get you when it sounds terrible and doesn’t get people dancing? The real focus should be to play the drums in a way that grooves and sounds amazing. For anyone who is serious about their drumming career, this is a must-have in your practice routine. This course is all about the groove, the feel, and YOUR sound.",
  "timing": "We, as drummers, are the foundation of any band. Even though the vast majority of listeners might not know this, but the drummer is by far the most essential musician in any band. If we rush, the whole band rushes. If we drag, the whole band drags. If we fail, the whole band fails. That’s why we have to build rock-solid timing. And this course will provide you with the perfect exercises.",
  "drum_fills": "Drum Fills are fun. Period. Include this course into your practice routine to get into all different kinds of fills. Fast & impressive, slow & useful, fast & useful and slow and impressive.",
  "grooves": "Grooves are an essential part of drumming. Regardless of the style or genre, almost every song needs a groove. This course will guide you through the endless possibilities of how grooves can be structured.",
  "warm_up": "Drumming is literally a high-performance sport. Christiano Ronaldo or Serena Williams would never think of going straight into a match and not be warmed up before. Because we all want to play this beautiful instrument for as long as physically possible, we have to be aware of our body and warm it up before going right into a practice session. This group of lessons will include a 5 to 10 minute warm-up block into each of your practice sessions.",
  "beginner_course": "It is your first time drumming. If you don't know how to hold the sticks or how to read sheet music - this is for you."
}

export const shortSkillDescription =
{
  "hand_technique": "This course will take you through rudiments, accent placements, and much more, which are all practicable to use on the drum set as well.",
  "hand_speed": "Who doesn't want to have fast hands?! Building up speed takes time and consistency. But with the right exercises and the right dose, it is no rocket science.",
  "independence": "Let's make your four limbs independent from each other. This course will take you as far as you can and as far as you want. Let's get into the fun stuff...",
  "groove_feel_sound": "This is a must-have in your practice routine for anyone serious about their drumming career. This course is all about the groove, the feel, and YOUR sound.",
  "timing": "We, as drummers, are the foundation of any band. If we rush, the whole band rushes. If we drag, the whole band drags. If we fail, the whole band fails. That's why we have to build rock-solid timing.",
  "drum_fills": "Drum Fills are fun. Period. Include this course into your practice routine to get into all different kinds of fills. Fast & impressive, slow & functional, fast & useful, and slow and impressive.",
  "grooves": "Grooves are an essential part of drumming. This course will guide you through the endless possibilities of how grooves can be structured.",
  "warm_up": "Drumming is literally a high-performance sport. This group of lessons will include a 5 to 10-minute warm-up block into each of your practice sessions.",
  "beginner_course": "It is your first time drumming. If you don't know how to hold the sticks or how to read sheet music - this is for you."
}

export const levelDescriptionList = [
  "no level selected - this means also no skill is selected",
  "Level 1: Starter - You have probably never sat behind a drum set before or had drum sticks in your hands.",
  "Level 2: Beginner - Maybe you have played for a few weeks in the past and are able to play a basic rock groove and a few fills.",
  "Level 3: Advanced Beginner - Maybe you've already played drums for a year or two, and you are comfortable with playing grooves and fills.",
  "Level 4: Medium Advanced - Maybe you are already playing in a band or jamming to a lot of music by yourself.",
  "Level 5: Advanced - At this point, you've probably played drums for a couple of years, and you are familiar with a lot of different things on the drumset. Maybe you are even thinking about doing this drumming thing professionally.",
  "Level 6: Pro - You are probably making money playing drums at this point. When you select this level, the lessons will really dive into the hardcore stuff.",
  "Level 7: Absolut Pro - You are a legend!"
]

