import React from "react";
import "./aboutUs.css"
import profile_pic_leander from "../../assets/img/leander_profile_pic.webp"
import profile_pic_felix from "../../assets/img/felix_profile_pic_2.webp"
import { icons } from "./icons";
import { ReactComponent as InstagramSVG } from './../../img/instagram.svg';

const IntroductionCard = ({ imageUrl, name, altImageText, instaHandle, mailTo, bio }) => {
  return (
    <div className="introduction-card">
      <img className="profile-pic" src={imageUrl} alt={altImageText} />
      <h1>{name}</h1>
      <a href={`https://www.instagram.com/${instaHandle}`} target="_blank" rel="noopener noreferrer">
        <InstagramSVG id="social-icon" />
      </a>
      <div className="introduction-card-text">
        <p>{bio[0]}</p>
        <p>{bio[1]}</p>
        <p>{bio[2]}</p>
      </div>
      <div className="card-footer">
        <a href={`mailto:${mailTo}`} className="mail-adress">{mailTo}</a>
      </div>
    </div>)
}

const AboutUs = () => {
  return (
    <div className="about-us" id="about-us">
      <a href="/">
      <h1 className="about-us-text">{icons.get("drumx_logo_nav")}</h1>
      </a>
      <div className="card-parent">
        <IntroductionCard
          imageUrl={profile_pic_leander}
          name={'Leander'}
          altImageText={'profile pic leander'}
          mailTo={'leander@drumx.com'}
          instaHandle={'leanderwidmoser'}
          bio={
            ['Leander is a professional drummer and teacher.',
              'He starting playing drums by the age of 5 and he is blessed to learn drums from the best drummers in the world like Anika Nilles, Jost Nickel, and Udo Dahmen.',
              'Leander is responsible for all the lessons and exercises you will learn.']}
        />

        <IntroductionCard
          imageUrl={profile_pic_felix}
          name={'Felix'}
          altImageText={'profile pic felix'}
          mailTo={'felix@drumx.com'}
          instaHandle={'codeanddrums'}
          bio={
            ['Felix is a Software Developer by day and a music enthusiast/multi instrumentalist by night.',
              'Making music since his childhood, his passion is playing the guitar. He just started to learn playing the drums with DrumX.',
              'Felix is responsible for the implementation of DrumX.']}
        />
      </div>
    </div>
  );
};

export default AboutUs;