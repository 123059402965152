import axios from "axios";
//import getAuthToken from "./loginServiceGateway";

//axios.defaults.headers.common["x-auth-token"] = getAuthToken();

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete
};
