import React, { Component } from "react";
import jtwDecode from "jwt-decode";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import NavBar from "./components/common/navBar";
import Footer from "./components/common/footer";
import Home from "./components/home";
import Register from "./components/registration/register";
import AboutUs from "./components/common/aboutUs";
import ProtectedRoute from "./components/protectedRoute";
import VerifyMail from './components/common/verifyMail';
import RequestPwRec from './components/common/pwRecovery/requestPwRec';
import ChangePw from './components/common/pwRecovery/changePw';
import Impressum from './components/common/impressum';
import { getAuthToken } from "./util/auth";
import PrivacyPolicy from './components/privacyPolicy';
import TermsAndConditions from './components/termsAndConditions';
import UpdateSkills from './components/updateSkills';
import AccountSettings from './components/accountSettings';
import { getGdprCookie, setInitialGdprCookie, isGoogleAnalyticsAllowed } from './components/cookieHandler';
import Subscription from './components/subscription';
import SubscriptionSuccessful from './components/subscriptionSuccessPage';
import LoginPage from "./components/loginPage";
import LaunchOverview from "./components/launchOverviewComponents/launchOverview";
import FixedMenuFooter from "./components/launchOverviewComponents/fixedMenuFooter";
import StatsAndProgress from "./components/launchOverviewComponents/statsAndProgress";

class App extends Component {
  state = {
    user: ""
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!process.env.REACT_APP_LOGINSERVICE_URL || !process.env.REACT_APP_SCHEDULESERVICE_URL) {
      console.error("one or multiple envs not set during build time");
      console.log(process.env.REACT_APP_LOGINSERVICE_URL);
      console.log(process.env.REACT_APP_SCHEDULESERVICE_URL);
    }

    try {
      if (!getGdprCookie()) {
        setInitialGdprCookie();
      }
    } catch (error) {
      console.error("error while setting initial gdpr cookie");
    }
    try {
      const jwt = getAuthToken();

      //just decoded - the verification of the jwt is done server side
      const user = jtwDecode(jwt);
      this.setState({ user: user });
    } catch (error) {
      // ignore -> is valid usecase (aka not logged in)
      console.log("======= logged out ======")
    }

  }




  render() {
    return (
      //TODO: add verification_state = false route? 
      <div>
        <Route component={NavBar} />
        <Switch>
          <Route path="/log-out" component={Home}></Route>
          <Route path="/about-us" component={AboutUs}></Route>
          <Route path="/impressum" component={Impressum}></Route>
          <Route path="/privacy-policy" component={PrivacyPolicy}></Route>
          <Route path="/terms-and-conditions" component={TermsAndConditions}></Route>
          <Route path="/register" component={Register}>
            {/*this.state.user && !this.state.user.verification_state && (
              <Redirect exact from="/register" to="/verify-mail" />)*/}
          </Route>
          <Route path="/login" component={LoginPage}>
            {this.state.user && <Redirect exact from="/login" to="/launch-overview" />}
          </Route>
          <Route path="/verify-mail/:secret" location={this.props.location} component={VerifyMail}>
          </Route>
          {/*todo: two times needed?*/}
          <Route path="/verify-mail/" component={VerifyMail}>
          </Route>
          <Route path="/request-password-change/" component={RequestPwRec}></Route>
          {/*todo: two times again? - possible to only have path with parameter?*/}
          <Route path="/change-password" component={ChangePw}></Route>
          <Route path="/change-password:secret" component={ChangePw}></Route>
          <ProtectedRoute path="/launch-overview" component={LaunchOverview} />
          <ProtectedRoute path="/stats-and-progress" component={StatsAndProgress} />
          <ProtectedRoute path="/renew-subscription" component={Subscription} />
          <ProtectedRoute path="/update-skills" component={UpdateSkills} />
          <ProtectedRoute path="/account-settings" component={AccountSettings} />
          <ProtectedRoute path="/subscription-success" component={SubscriptionSuccessful} />
          <Route path="/" component={Home}>
            {this.state.user && <Redirect exact from="/" to="/launch-overview" />}
          </Route>
        </Switch>
        <Route component={Footer} />
      </div >
    );
  }
}

export default App;
