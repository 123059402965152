import http from "./rest";

async function getVideoIFrame(vimeoUrl) {
  const encodedUrl = encodeURI(vimeoUrl);
  if (vimeoUrl) {
    let res;
    await http.get('https://vimeo.com/api/oembed.json?url=' + encodedUrl + '&width=640&height=360&dnt=1')
      .then((response) => {
        res = response.data.html;
      })
      .catch((err) => { console.error(`could not fetch video from vimeo: ${err}`) });

    return res;
  }
}

export { getVideoIFrame };