import React, { useEffect, useState } from "react";
import "./footer.css";
import { CookieConsentPopUp } from '../cookieConsentPopUp';
import { cookieConsentDisplayed } from "../cookieHandler";
import { icons } from "../common/icons.jsx"
import { useAuthentication } from "../../hooks/authHook.js";
import FixedMenuFooter from "../launchOverviewComponents/fixedMenuFooter.jsx";

const Footer = () => {
  const [showCookiePref, setShowCookiePref] = useState(!cookieConsentDisplayed());
  const { isAuthenticated, logout } = useAuthentication();
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    if(isAuthenticated) {
      setShowFooter(false);
    }else{  
      setShowFooter(true);
    }
  }, [isAuthenticated]);

  const getFooter = (loggedInSize) => {
    return (
     <div className={`footer ${loggedInSize}`}>
      <div className="footer-content">
        <div className="footer-header">
          <div className="footer-logo">
          {icons.get("drumx_logo_nav")}
          </div>
          <p>© 2024 DrumX, All rights reserved</p>
        <button className="cookie-button" onClick={() => setShowCookiePref(!showCookiePref)}>🍪 cookie preferences</button>
        </div>

        <div className="footer-links">
          <h3>Links</h3>

          <ul className="list">
            <li>
              <a href="/about-us">
                About Us
              </a>
            </li>
            <li>
            </li>
            <li>
              <a href="/terms-and-conditions">
                Terms &amp; Condition
              </a>
            </li>
            <li>
              <a href="/privacy-policy">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/impressum">
                Impressum
              </a>
            </li>
          </ul>

        </div>
        <div className="footer-follow-us">
          <h3>Follow Us</h3>

          <ul className="list">
            <li>
              <a href="https://www.facebook.com/DrumX-2167613836614974/" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/drumxschool/" target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCAxLXoeq4_oQ_rUuZUCbraQ" target="_blank" rel="noopener noreferrer">
                Youtube
              </a>
            </li>
          </ul>

        </div>
        {showCookiePref ? <CookieConsentPopUp showCookiePref={showCookiePref} setShowCookiePref={setShowCookiePref} /> : null}
      </div>
    </div> 
    );
  }


  return (
    showFooter ?
    getFooter("logged-out") : 
   <div>
 {getFooter("logged-in")}
   <FixedMenuFooter />
   </div> 
    
  );
};

export default Footer;
