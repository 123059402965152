import React, { Component } from "react";
import "./updateSkills.css"
import { levelNames, skillDescription, skillDisplayName } from "./common/globalStrings";
import { getSkillPreference, getProgressCounts, updateSkillPreference, getCustomerProgress } from "../services/scheduleServiceGateway"
import { icons } from './common/icons';
import { getAuthToken, deleteAuthToken, isAuthCookieExpired } from "../util/auth";
import ProgressGauge from "./schedule/progressGauge";


//TODO refactor tabs and what info is necessary in state
//TODO refactor to functional component
//TODO refactor to use custom hooks
// for state and for authentication
//TODO fix screensize small tabs are bleeding 

class UpdateSkills extends Component {

  state = {
    /*Todo refactor - duplicate code see register*/
    skillSelfRating: [
      { skillName: "hand_technique", selected: false, level: "level not set" },
      { skillName: "hand_speed", selected: false, level: "level not set" },
      { skillName: "independence", selected: false, level: "level not set" },
      { skillName: "groove_feel_sound", selected: false, level: "level not set" },
      { skillName: "timing", selected: false, level: "level not set" },
      { skillName: "drum_fills", selected: false, level: "level not set" },
      { skillName: "grooves", selected: false, level: "level not set" },
      { skillName: "beginner_course", selected: false, level: "level not set" }

      /*{ skillName: "foot_speed_and_technique", selected: false, level: "level not set" },
      { skillName: "Foot Speed And Technique", selected: false, level: "level not set" },
      { skillName: "Independence", selected: false, level: "level not set" },
      { skillName: "Soloing And Improvisation", selected: false, level: "level not set" },
      */
    ],
    skillInitialRating: [
      { skillName: "hand_technique", selected: false, level: "level not set" },
      { skillName: "hand_speed", selected: false, level: "level not set" },
      { skillName: "independence", selected: false, level: "level not set" },
      { skillName: "groove_feel_sound", selected: false, level: "level not set" },
      { skillName: "timing", selected: false, level: "level not set" },
      { skillName: "drum_fills", selected: false, level: "level not set" },
      { skillName: "grooves", selected: false, level: "level not set" },
      { skillName: "beginner_course", selected: false, level: "level not set" }
    ],
    customerSkillPreference: [],
    customerProgress: [],
  }

  async componentDidMount() {
    if (getAuthToken() && isAuthCookieExpired()) {
      deleteAuthToken();
      this.props.history.push("/");
      return;
    }
    await this.updateAccountSettingState();
  }

  async updateAccountSettingState() {
    let customerSkillPreference;
    let customerProgress;
    try {
      customerSkillPreference = await getSkillPreference(getAuthToken());
      customerProgress = await getCustomerProgress(getAuthToken());

    } catch (err) {
      //deletes invalid auth-token... is this a good idea?
      if (err.response && err.response.status === 403) {
        deleteAuthToken();
        this.props.history.push("/log-out");
      }
      return;
    }
    let skillInitialRating = [...this.state.skillInitialRating];

    for (let i = 0; i < skillInitialRating.length; i++) {
      [...customerSkillPreference.data].forEach(skill => {
        if (skill.focus === skillInitialRating[i].skillName) {
          skillInitialRating[i].selected = true;
          skillInitialRating[i].level = skill.level;
        }
      })
    }

    const progress = await getProgressCounts(getAuthToken());

    let customerSkillPreferences = await getSkillPreference(getAuthToken());

    const accountSettingState = { skillInitialRating: skillInitialRating, customerSkillPreference: customerSkillPreferences.data, progress: progress, customerProgress: customerProgress.data }
    this.setState(accountSettingState);
  }

  levelUpSkillPref = async (skill, level) => {
    const updatedLevel = level + 1;
    try {
      await updateSkillPreference(getAuthToken(), { skillName: skill, selected: true, level: levelNames[updatedLevel] });
      await this.updateAccountSettingState();
      this.updateSkillSelfRating(skill, levelNames[updatedLevel]);
    } catch (err) {
      console.log("error in level up", err);
    }
  }

  updateLevel(skill, level) {
    console.log("update level")
    const currentSkills = [...this.state.skillSelfRating];

    const changedSkill = currentSkills.filter(elem => elem.skillName === skill);

    if (changedSkill.length > 0) {
      changedSkill[0].level = levelNames[level.target.value];

      if (changedSkill[0].level === levelNames[0]) {
        changedSkill[0].selected = false;
      } else {
        changedSkill[0].selected = true;
      }
      this.setState({ changedSkill });
    }
  }

  updateSkillSelfRating(skillName, level) {
    const updatedSkillSelfRating = [...this.state.skillSelfRating].map(skill => ( 
      skill.skillName === skillName ? { ...skill, level: level } : skill
    ));
    this.setState({ skillSelfRating: updatedSkillSelfRating });
  }

  getCurrentLevel(skillName) {
    return levelNames.indexOf(this.state.skillInitialRating.find(e => e.skillName === skillName).level);
  }

  getRadioButton(skillName) {
    if (skillName === "hand_technique") return (<input className="radio" key={`${skillName}_radio_button`} id={skillName} name="group" type="radio" defaultChecked />);
    return (<input className="radio" key={`${skillName}_radio_button`} id={skillName} name="group" type="radio" />);
  }

  async sendLevelUpdate(skillName) {
    try {
      await updateSkillPreference(getAuthToken(), this.state.skillSelfRating.find(e => e.skillName === skillName))
    } catch (err) {
      console.error("err", err)
    }
    await this.updateAccountSettingState();
  }

  buttonDisabled(skill) {
    return !skill.selected || skill.level === this.state.skillInitialRating.find(e => e.skillName === skill.skillName).level;
  }

  render() {
    return (
      <div className="update-skills">
        <div className="wrapper">
          {this.state.skillSelfRating.map(skill => (
            this.getRadioButton(skill.skillName)
          ))}
          <div className="tabs">
            {this.state.skillSelfRating.map(skill => (
              <label className="tab" key={`${skill.skillName}_tab`} id={skill.skillName + "-tab"} htmlFor={skill.skillName}>{icons.get(skill.skillName + "_mini")}</label>
            ))}
          </div>
          <div className="panels">
            {this.state.skillSelfRating.map(skill => (
              <div className="panel" key={`${skill.skillName}_panel`} id={skill.skillName + "-panel"}>
                <h2 className="panel-title">{skillDisplayName[skill.skillName]}</h2>

                <div className="progress-visualization">
                  <ProgressGauge
                    key={`${skill.skillName}_progressGauge`}
                    skillPref={skill.skillName}
                    icon={icons.get(skill.skillName + "_mini")}
                    customerProgress={this.state.customerProgress}
                    levelUp={this.levelUpSkillPref}
                    isClickableForLevelUp={false}
                  />
                </div>
                <div className="skillUpdate" id={skill.skillName}>
                  <input
                    className="someInput"
                    id="level-update-input"
                    type="range"
                    min="0"
                    /*key necessary so that default value updates: https://stackoverflow.com/a/66748879 */
                    key={this.getCurrentLevel(skill.skillName)}
                    defaultValue={this.getCurrentLevel(skill.skillName)}
                    max={skill.skillName === "beginner_course" ? 1 : levelNames.length - 1}
                    onChange={input =>
                      this.updateLevel(skill.skillName, input)} />
                  <p className="skill-name-info">{levelNames.indexOf(skill.level) ? `Update ${skillDisplayName[skill.skillName]} to: level ` + levelNames.indexOf(skill.level) : "Move slider to select new level"} </p>
                </div>
                <div id="skill-pref-update-btn">
                  <button onClick={(e) => this.sendLevelUpdate(skill.skillName)} disabled={this.buttonDisabled(skill)}>Update</button>
                </div>
                <h2>Description</h2>
                <p className="account-settings-skill-description">{skillDescription[skill.skillName]}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

}
export default UpdateSkills;