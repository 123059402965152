import React, { Component } from "react";
import "./requestPwRec.css"
import { requestPwRecMail } from "../../../services/loginServiceGateway";

const Joi = require("joi");


const emailSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
});

class RequestPwRec extends Component {
  state = {
    emailFormData: {
      email: ""
    },
    emailValidationError: "",
    successMessage: ""
  }

  validate = () => {
    const { error } = emailSchema.validate(this.state.emailFormData, { abortEarly: false });
    if (!error) return null;
    console.log(error)
    return error;
  };

  handleChange = ({ currentTarget: input }) => {
    const emailFormData = { ...this.state.emailFormData };
    emailFormData[input.name] = input.value;
    this.setState({ emailFormData });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const emailValidationError = this.validate();
    this.setState({ emailValidationError: emailValidationError || {} });
    if (emailValidationError) return;

    try {
      await requestPwRecMail(this.state.emailFormData.email);
      const email = this.state.emailFormData.email;
      let updatedState = { ...this.state };
      updatedState.emailFormData.email = "";
      updatedState.successMessage = this.newlineText(`Password recovery email sent successfully to ${email}, please check your emails and follow our instructions.\nIn case that you don't find the email, please check your Spam folder first :)`);
      this.setState(updatedState)
    }
    catch (error) {
      this.setState({ successMessage: "The account is already verified or does not exist." });
    }
  };

  newlineText(text) {
    const newText = text.split('\n').map(str => <p>{str}</p>);
    return newText;
  }


  render() {
    return (
      <div className="pw-rec-body">
        <div className="pw-rec-card">
          <div className="pw-rec-content">
            <h1>Forgot your password?</h1>
            <p>Recover your DrumX access via your email adress.</p>
            <div className="pw-rec-container">
              <form className="request-pw-rec-mail-form" onSubmit={this.handleSubmit}>
                <label htmlFor="email">email:
                </label>
                <input
                  className="pw-rec-input"
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.emailFormData.email}
                  error={this.state.emailValidationError}
                  placeholder="@" 
                  />
                <div id="pw-rec-form-error">
                  {this.state.emailValidationError.message && <div className="validation-error" >{this.state.emailValidationError.message}</div>}
                </div>
                <div className="get-btn">
                  <button
                    className="pw-rec-submit-btn"
                    type="submit"
                    disabled={!this.state.emailFormData.email}
                  >Request email for password change</button>
                </div>
              </form>
              {this.state.successMessage && <div className="success-message">{this.state.successMessage}</div>}
            </div>

          </div>
        </div>
      </div >

    )
  };

}

export default RequestPwRec;