import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
const cookies = new Cookies();
const jwtKey = "auth-token";


function hasAuthTokenStored() {
  if (cookies.get(jwtKey)) return true;
  else return false;
}

function storeAuthToken(jwtValue) {
  cookies.set(jwtKey, jwtValue, { path: '/' });
}

function getAuthToken() {
  return cookies.get(jwtKey) || false;
}

function deleteAuthToken() {
  return cookies.remove(jwtKey, { path: '/' });
}

function getDecodedAuthToken() {
  try{
   return jwt_decode(getAuthToken());
  } catch(err){
    console.log(err)
    return false;
  }
}

function isAuthCookieExpired(){
  const decodedToken = getDecodedAuthToken();
  const expirationTime = decodedToken.exp * 1000;

  const currentTime = new Date().getTime();
  return expirationTime < currentTime;
}



export { hasAuthTokenStored, storeAuthToken, getAuthToken, deleteAuthToken, getDecodedAuthToken, isAuthCookieExpired  };
