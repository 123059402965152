import React from 'react';
import './statsAndProgress.css';
import { icons } from "../common/icons.jsx"
import StatsOverview from './statsOverview';
import ProgressGauge from '../schedule/progressGauge';
import { getAuthToken } from "../../util/auth";
import { useCustomerProgress, useCustomerSkillPreference } from '../../hooks/fetchDataHook.js';
import { skillDisplayName } from '../common/globalStrings.jsx';

const StatsAndProgress = () => {
      const { customerProgress, refetchCustomerProgress } = useCustomerProgress(getAuthToken());
      const customerSkillPreference = useCustomerSkillPreference(getAuthToken());
      const skillPrefOrder = customerProgress?.find(skill => skill.skillName === 'beginner_course')?.levelProgress >= 100 ?
            ['hand_technique', 'hand_speed', 'independence', 'groove_feel_sound', 'timing', 'drum_fills', 'grooves', 'beginner_course']
            :
            ['beginner_course', 'hand_technique', 'hand_speed', 'independence', 'groove_feel_sound', 'timing', 'drum_fills', 'grooves'];



      const getNextStepDescription = (skillPref) => {
            return (skillPref.levelProgress >= 100 && (skillPref.skillLevel === 'absolute_pro' || skillPref.skillName === 'beginner_course') ? (
                  <div className="info-level-container">
                        <h1 className="info-level-name">{skillDisplayName[skillPref.skillName]}</h1>
                        <p className="info-level-text">You have mastered this skill!</p>
                  </div>
            ) :

                  skillPref.levelProgress >= 80 && skillPref.skillName !== 'beginner_course' ? (
                        <div className="info-level-container">
                              <h1 className="info-level-name">{skillDisplayName[skillPref.skillName]}</h1>
                              <p className="info-level-text">You can level up!</p>
                              <p className="info-level-sub-text">Just hit the blinking icon!</p>
                        </div>
                  )
                        :
                        <div className="info-level-container">
                              <h1 className="info-level-name">{skillDisplayName[skillPref.skillName]}</h1>
                              <p className="info-level-text">Practice to improve! </p>
                              <p className="info-level-sub-text">If the level does not fit adjust it in the tab 'Level Up'!</p>
                        </div>)
      }

      const updateCustomerProgress = async () => {
            try {
                  await refetchCustomerProgress();
            } catch (err) {
                  console.log("error in updateCustomerProgress", err);
            }
      }

      return (
            <div className="stats-and-progress-container">
                  <StatsOverview customerSkillPreference={customerSkillPreference} />
                  <div className="skill-preference-progress">
                        {skillPrefOrder.map((skillName, index) => {
                              const skillPref = customerProgress?.find(skill => skill.skillName === skillName);
                              return (
                                    <React.Fragment key={index}>
                                          {skillPref ? (
                                                <div className="progress-gauge-container">
                                                      <ProgressGauge
                                                            key={`${skillPref.skillName}_progressGauge`}
                                                            skillPref={skillPref.skillName}
                                                            icon={icons.get(skillPref.skillName + "_mini")}
                                                            customerProgress={customerProgress}
                                                            updateProgressState={updateCustomerProgress}
                                                            isClickableForLevelUp={true}
                                                      />
                                                      {getNextStepDescription(skillPref)}
                                                </div>
                                          ) : null
                                          }
                                    </React.Fragment>

                              )
                        }
                        )}
                  </div>
            </div>
      );
};

export default StatsAndProgress;
