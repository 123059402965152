import React from "react";
import './subscriptionSuccessPage.css'
import { getAuthToken } from "../util/auth";
import jwtDecode from "jwt-decode";
import { isGoogleAnalyticsAllowed } from "./cookieHandler";
import ReactGA from 'react-ga4';

const SubscriptionSucessPage = () => {
    if (isGoogleAnalyticsAllowed()) {
        ReactGA.event('subscription_checkout_completed');
    }
    const getUsername = () => {
        let jwt = getAuthToken();
        const { firstname } = jwtDecode(jwt);
        return firstname;
    }
    return (<>
        <div className="subscription-successful">
            <div className="subscription-successful-textbox">
                <h1>Hi {getUsername()}!</h1>
                <p>Welcome to DrumX!</p>
                <p> Your subscription is now active, everything is set up and ready for you to start exploring.</p>
                <p>Jump right into <a href="/launch-overview"> practice</a> and start drumming! </p>
                <p>Happy Drumming!</p>
            </div>
        </div>
    </>

    )
};

export default SubscriptionSucessPage;