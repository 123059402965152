import React, { Component } from "react";
import "./navBar.css";
import { icons } from "../common/icons.jsx"
import Hamburger from "./hamburger.jsx";
import { ReactComponent as UserAccountSVG } from '../../img/user_account.svg';

import {
  hasAuthTokenStored,
  deleteAuthToken
} from "../../util/auth";


class NavBar extends Component {
  state = {
    showMenuContent: false
  };

  handleBugerMenu = () => {
    console.log("handleBugerMenu")
    const showMenuContent = !this.state.showMenuContent;
    this.setState({ showMenuContent });
  }

  logout() {
    deleteAuthToken();
    window.location = "/";
  }

  getMenuItem() {
    if (!hasAuthTokenStored()) {
      return (
      <>
      <a href="/login">
      <UserAccountSVG id="user-account-icon"/>
      </a>
      </>
      );
    } else {
      return (
        <>
        <div className="navbar-link-container">
        <a id="navbar-link" href="/launch-overview">Practice</a>
        <a id="navbar-link" href="/stats-and-progress">Stats</a>
          <a id="navbar-link" href="/update-skills">Change Skills</a>
          <a id="navbar-link" href="/account-settings">Account Settings</a>
          <button
            type="button"
            onClick={this.logout}
            >
            Logout
          </button>

        </div>
        <div className="navbar-link-container-burger">
          {this.state.showMenuContent && 
          <div className="burger-menu-items">
            <ul>
              <li><a className="navbar-link-burger" href="/launch-overview">Practice</a></li>
              <li><a className="navbar-link-burger" href="/stats-and-progress">Stats</a></li>
              <li><a className="navbar-link-burger" href="/update-skills">Change Skills</a></li>
              <li><a className="navbar-link-burger" href="/account-settings">Account Settings</a></li>
              <li><a className="navbar-link-burger" href="/about-us">About Us</a></li>
              <li><a className="navbar-link-burger" href="/privacy-policy">Privacy Policy</a></li>
              <li><a className="navbar-link-burger" href="/terms-and-conditions">Terms and Conditions</a></li>
              <li><a className="navbar-link-burger" href="/impressum">Impressum</a></li>
              <li> <button
            type="button"
            onClick={this.logout}
            >
            Logout
          </button></li>
            </ul>
            </div>
            }
          <button id="burger-button" onClick={this.handleBugerMenu}>
          <Hamburger clicked={this.state.showMenuContent}/>
          </button>
        </div>
            </>
      );
    }
  }

  render() {
    return (
      <div className="navbar">
        <a href="/">
          {icons.get("drumx_logo_nav")}
        </a>
        {this.getMenuItem()}
      </div>
    );
  }
}

export default NavBar;
