import React, { useState } from "react";
import RadarChart from 'react-svg-radar-chart';
import { ReactComponent as CalendarSVG } from '../../img/calendar.svg';
import { ReactComponent as ChartSVG } from '../../img/chart.svg';
import ProgressCalendar from '../schedule/progressCalendar';
import { getAuthToken } from "../../util/auth";
import { getPracticeActivity } from "../../hooks/fetchDataHook";
import './statsOverview.css'

const radarMetaData = { color: 'black' };
const initialRadarData = {
    hand_technique: .1,
    hand_speed: .1,
    independence: .1,
    drum_fills: 0.1,
    groove_feel_sound: 0.1,
    grooves: 0.1,
    timing: 0.1,
};

const options = {
    wrapCaptionAt: 12,
    captionLineHeight: 10,
    captionMargin: 17,
}
const captions = {
    // spaces necessary to align the captions => see options wrapCaptionAt
    independence: 'Independence',
    groove_feel_sound: "Groove Feel   Sound",
    hand_speed: 'Hand         Speed',
    timing: 'Timing',
    drum_fills: 'Drum         Fills',
    grooves: 'Grooves',
    hand_technique: 'Hand        Technique',
};

const levelMapping = {
    "starter": .2,
    "beginner": .3,
    "advanced_beginner": .4,
    "medium_advanced": .5,
    "advanced": .7,
    "pro": .8,
    "absolute_pro": .9
}


function StatsOverview({ userName, customerSkillPreference }) {
    const authToken = getAuthToken();
    const { customerPracticeActivity, activityCalendarLoading } = getPracticeActivity(authToken);
    const [showProgressCalendar, setShowProgressCalendar] = useState(false);

    const toggleActivityCalendar = () => {
        setShowProgressCalendar(!showProgressCalendar);
    }
    const getRadarChartData = (customerSkillPreference) => {
        if (!customerSkillPreference || customerSkillPreference?.length === 0) return [{ data: initialRadarData, meta: radarMetaData }];
        const newData = { ...initialRadarData };
        customerSkillPreference.forEach((skill) => {
            newData[skill.focus] = levelMapping[skill.level];
        });
        return [{ data: newData, meta: radarMetaData }];
    };
    const customerSkillPrefForRadar = getRadarChartData(customerSkillPreference);

    const calculateAvgLevelName = () => {
        if (!customerSkillPreference || customerSkillPreference.length === 0) return 'Starter';
        let sum = 0;
        customerSkillPreference.forEach((skill) => {
            sum += levelMapping[skill.level];
        });
        let numResult = sum / customerSkillPreference.length;

        let levelName =
            numResult <= 0.2 ? 'Starter' :
                numResult <= 0.3 ? 'Beginner' :
                    // todo find better novice
                    numResult <= 0.4 ? 'Novice' :
                        numResult <= 0.5 ? 'Intermediate' :
                            numResult <= 0.7 ? 'Advanced' :
                                numResult <= 0.8 ? 'Professional' :
                                    numResult <= 1 ? 'Grandmaster' :
                                        'Starter';

        return levelName;
    }

    const totalPracticeTimeInHours = () => {
        if (!customerPracticeActivity || customerPracticeActivity.length === 0) return 0;
        let sum = 0;
        customerPracticeActivity.forEach((activity) => {
            sum += activity.practiceDurationMinutes;
        });
        return Math.floor(sum / 60);
    }

    const totalPracticeLessons = () => {
        if (!customerPracticeActivity || customerPracticeActivity.length === 0) return 0;
        let sum = 0;
        customerPracticeActivity.forEach((activity) => {
            sum += activity.count;
        });
        return sum;
    }
    
    function findLongestStreak() {
        if (!customerPracticeActivity || customerPracticeActivity.length === 0) return 0;

        let sortedCustomerPracticeActivity = customerPracticeActivity.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
        });
        let currentStreak = 0;
        let longestStreak = 0;
        
        for (let i = 0; i < sortedCustomerPracticeActivity.length - 1; i++) {
            const currentDate = new Date(sortedCustomerPracticeActivity[i].date);
            const nextDate = new Date(sortedCustomerPracticeActivity[i + 1].date);

            // Check if the next date is consecutive
            if ((nextDate - currentDate) / (24 * 60 * 60 * 1000) === 1) {
                // Increase the current streak
                currentStreak++;

                // Update the longest streak if the current streak is longer
                longestStreak = Math.max(longestStreak, currentStreak);
            } else {
                // Reset the current streak
                currentStreak = 0;
            }
        }

        // Add 1 to account for the first date in the streak
        return longestStreak + 1;
    }

    const getProgressCalendar = (screen) => {
        return (

            <div className={`${screen}`}>
                <div className="stats-overview">
                    <div className="stats-progress-calendar">
                        <ProgressCalendar customerPracticeActivityData={customerPracticeActivity} loading={activityCalendarLoading} />
                    </div>
                </div>

                <div className="overall-stats">
                    <div className="stat-pack">
                        <p>{totalPracticeTimeInHours()}</p>
                        <p>hours</p>
                    </div>
                    <div className="stat-pack">
                        <p>{totalPracticeLessons()}</p>
                        <p>lessons</p>
                    </div>
                    <div className="stat-pack">
                        <p>{findLongestStreak()} day</p>
                        <p>max streak</p>
                    </div>
                    <button onClick={toggleActivityCalendar}>

                        <ChartSVG id="chart_icon" />
                    </button>
                </div>
            </div>
        );
    }
    // todo add indicator that there are two pages (little dots)
    // todo show next level in radar chart and current one in different colors
    return (
        <>
            {!showProgressCalendar ?
                <div className="stats-overview-container">
                    <div className="stats-overview">
                        <div className="skill-chart">
                            <RadarChart
                                captions={captions}
                                data={customerSkillPrefForRadar || [{ data: initialRadarData, meta: radarMetaData }] || []}
                                size={190}
                                options={options}
                            />
                        </div>
                        <div className="overall-infos">
                            <div id="name">
                                <h1>{userName}</h1>
                            </div>
                            <div id="level">
                                <h1>{calculateAvgLevelName()}</h1>
                            </div>
                        </div>
                    </div>
                    <div className={`overall-stats ${{ showProgressCalendar } ? 'single-item' : ''}`}>
                        {//<WeeklyStreak/> 
                        }
                        <button onClick={toggleActivityCalendar}>
                            <CalendarSVG id="calendar_icon" />
                        </button>
                    </div>
                    {getProgressCalendar("desktop")}
                </div>

                :
                getProgressCalendar("mobile")
            }
        </>
    );
}

export default StatsOverview;