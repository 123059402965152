import React, { Component } from "react";
import "./registrationForm.css";
import Loader from "../loader";
import CountryDropdown from "./CountryDropdown.jsx";

const Joi = require("joi");
const { joiPasswordExtendCore } = require('joi-password');
const JoiPassword = Joi.extend(joiPasswordExtendCore);



const registrationSchema = Joi.object({
  firstname: Joi.string().min(2).required(),
  lastname: Joi.string().min(2).required(),
  country: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: JoiPassword.string()
    .min(8)
    .minOfSpecialCharacters(1)
    .minOfLowercase(1)
    .minOfUppercase(1)
    .minOfNumeric(2)
    .noWhiteSpaces()
    .required(),
});

class registrationForm extends Component {
  /*
  todo: check single source of truth
  */
  state = {
    errors: { email: "", country:"", password: "", firstname: "", lastname: "" },
    legalCheckboxes: {
      termsAndContionsConfirmed: false,
      privacyPolicyConfirmed: false
    },
    disableButton: false,
    showLoader: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  validate = () => {
    const options = {
      abortEarly: false
    };
    const { error } = registrationSchema.validate(this.props.registrationFormData, options);

    if (!error) return null;

    const errors = {};

    for (let item of error.details) {
      console.log("item: ", item)
      errors[item.path[0]] = item.message;
    }
    console.log("errors", errors);
    return errors;
  };

  handleCheckboxes({ currentTarget: input }) {
    const legalCheckboxes = { ...this.state.legalCheckboxes };
    legalCheckboxes[input.name] = input.checked;

    this.setState({ legalCheckboxes });
  }


  handleFormSubmit = async (event, ctx) => {
    event.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    try {
      this.setState({ disableButton: true });
      this.setState({ showLoader: true });
      await this.props.submitRegistration(event);
      //ctx.next();
    }
    catch (error) {
      console.log(error);
      this.setState({ disableButton: false });
      this.setState({ showLoader: false });

      if (error.response?.status === 409) {
        const error1 = { email: "email already in use" };
        this.setState({ errors: error1 });
      }
    }
  }

  render() {
    return (
      <>
        {this.state.showLoader ? 
        <Loader/>
             : null}
      <div className="reg-body">
        {this.props.getOverallProgressBar(2)}
        <div className="form-container">
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="banner">
              <h1>Become the best drummer you can be</h1>
            </div>
            <div className="item">
              <div className="name-item">
              <label htmlFor="firstname">First name</label>
                <input
                  className="reg-input-text"
                  type="text"
                  name="firstname"
                  id="firstname"
                  onChange={this.props.handleRegistrationFormChange}
                  value={this.props.registrationFormData.firstname}
                  error={this.state.errors.firstname}
                  placeholder="First name"
                  autoComplete="given-name"
                />
                {this.state.errors.firstname && <div className="validation-error">{this.state.errors.firstname}</div>}
                </div>
              <div className="name-item">
                <label htmlFor="lastname">Last name</label>
                <input
                  className="reg-input-text"
                  type="text"
                  name="lastname"
                  id="lastname"
                  onChange={this.props.handleRegistrationFormChange}
                  value={this.props.registrationFormData.lastname}
                  error={this.state.errors.lastname}
                  placeholder="Last name"
                  autoComplete="family-name"
                />
                {this.state.errors.lastname && <div className="validation-error">{this.state.errors.lastname}</div>}
              </div>
            </div>
              <div className="item">
              <CountryDropdown 
                value={this.props.registrationFormData.country}
                onChange={this.props.handleRegistrationFormCountrySelection}
              />
              {this.state.errors.country && <div className="validation-error">{this.state.errors.country}</div>}
              </div>
            <div className="item">
              <label htmlFor="email">Email</label>
              <input
                className="reg-input-text"
                type="email"
                name="email"
                id="email"
                value={this.props.registrationFormData.email}
                onChange={this.props.handleRegistrationFormChange}
                error={this.state.errors.email}
                placeholder="@" 
                autoComplete="email"
                />
              {this.state.errors.email && <div className="validation-error">{this.state.errors.email}</div>}
            </div>
            <div className="item">
              <label htmlFor="password">Password</label>
              <input
                className="reg-input-password"
                type="password"
                name="password"
                id="password"
                value={this.props.registrationFormData.password}
                onChange={this.props.handleRegistrationFormChange}
                error={this.state.errors.password}
                autoComplete="new-password"
              />
              {this.state.errors.password && <div className="validation-error">{this.state.errors.password}</div>}
            </div>
            <div className="checkbox-item">
              <input
                type="checkbox"
                name="termsAndContionsConfirmed"
                id="termsAndContionsConfirmed"
                checked={this.state.legalCheckboxes.termsAndContionsConfirmed}
                onChange={(e) => { this.handleCheckboxes(e) }}
              />
              <label id="legal-check" htmlFor="termsAndContionsConfirmed">I agree to the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Condition</a>. (Ich habe die <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">AGBen</a> gelesen und stimme diesen zu).</label>
            </div>
            <div className="checkbox-item">
              <input
                type="checkbox"
                name="privacyPolicyConfirmed"
                id="privacyPolicyConfirmed"
                checked={this.state.legalCheckboxes.privacyPolicyConfirmed}
                onChange={(e) => { this.handleCheckboxes(e) }}
              />
              <label id="legal-check" htmlFor="privacyPolicyConfirmed">I agree to the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. (Ich habe die <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a> gelesen und stimme dieser zu).</label>
            </div>
            <div className="buttons">
              <button
                className="reg-button"
                id="btn-back"
                type="button"
                onClick={this.props.ctx.previous}
              >
                back
              </button>
              <div>

              <button
                className="reg-button"
                id="btn-submit"
                type="button"
                onClick={(e) => this.handleFormSubmit(e, this.props.ctx)}
                disabled={!(this.state.legalCheckboxes.privacyPolicyConfirmed === true) || !(this.state.legalCheckboxes.termsAndContionsConfirmed === true) || this.state.disableButton}
                >
                register
              </button>
              <div className="register-info">You won't be charged.</div>
              </div>
            </div>
          </form>
        </div>
      </div >
      
             </>
    );
  }
}
export default registrationForm;
