import React, { useState } from 'react';
import './loginPage.css';
import { icons } from "./common/icons.jsx";
import { login } from "../services/loginServiceGateway";
import Joi from 'joi';
import { storeAuthToken } from "../util/auth";

function LoginPage() {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });

  const schema = Joi.object({
    email: Joi.string()
      .email({
        // todo allow IANA tlds check allow: true
        tlds: { allow: false }
      })
      .required()
      .label("Email"),
    password: Joi.string()
      .required()
      .label("Password")
  });

  const validate = () => {
    const options = {
      abortEarly: false
    };

    const { error } = schema.validate(credentials, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    console.log(errors);
    return errors;
  };


  const handleChange = event => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const errors = validate();
    setErrors({ ...errors } || {});
    if (errors) return;
    doSubmit();
  };

  const doSubmit = async () => {
    try {
      const { data: jwt } = await login(
        credentials.email,
        credentials.password
      );
      storeAuthToken(jwt);
      // history.push("/launch-overview");
      // todo: fix routing: quick fix to ensure reload of the footer 
      window.location = "/launch-overview";
    } catch (error) {
      if (error.response && (error.response.status === 400 || 403)) {
        const errors = { email: "" }
        errors.email = error.response.data;
        setErrors({ ...errors } || {});
      } else {
        console.error(`An error during login happened ${error}`);
      }
    }
  };


  return (
    <div
      className="login-form-container"
      title="Login"
    >
      <form
        onSubmit={handleSubmit}
        className="login-form"
        id="login-form"
      >
        <a href="/">
          <h2>{icons.get("drumx_logo_nav")}</h2>
        </a>
        <div className="login-input-container">
          <input
            className="login-input"
            name="email"
            type="email"
            placeholder="email"
            value={credentials.email}
            label="Email"
            onChange={handleChange}
            error={errors.email}
            autoComplete="email"
          />
        </div>
        <a href="/register" id="login-input-link" tabIndex="1">No account?</a>
        {errors.email && <div className="validation-error" id="login-error-email">{errors.email}</div>}
        <div className="login-input-container">

          <input
            className="login-input"
            name="password"
            type="password"
            placeholder="password"
            value={credentials.password}
            label="Password"
            onChange={handleChange}
            error={errors.password}
          />
        </div>
        <a href="/request-password-change" id="login-input-link" >Forgot your password?</a>
        {errors.password && <div className="validation-error" id="login-error-password">{errors.password}</div>}

        <button type="submit">
          login
        </button>
      </form>
    </div>
  );
}

export default LoginPage;