import React from "react";
import './termsAndConditions.css'
let lastModified = new Date('2022-12-04T16:15');
lastModified.toLocaleString('de', { timeZone: 'Europe/Berlin', timeZoneName: 'long' });
export function getTermsAndConditionVersion() {
  return lastModified;
}
const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <h1>Allgemeine Geschäftsbedingungen</h1>
      <p id="last-modified">Zuletzt angepasst: {lastModified.toLocaleDateString('de') + " " + lastModified.toLocaleTimeString()}</p>
      <p>Über den Abschluss eines Vertrages zwischen der</p>
      <p>
        DrumX UG (haftungsbeschränkt)<br />
        Peter-Dörfler-Str. 6<br />
        82131 Stockdorf<br />
        Geschäftsführer:<br />
        Leander Widmoser und<br />
        Felix Merkl <br />
      </p>
      <p>(nachfolgend „Anbieter“)</p>
      <p>und den in § 1 Absatz 2 näher bezeichneten Kund*innen</p>
      <p>(nachfolgend „Besteller*innen“).</p>

      <h2>§ 1 Geltungsbereich, Begriffsbestimmung</h2>
      <ol>
        <li>Die nachfolgenden Bedingungen regeln die Nutzung der auf der Homepage der DrumX UG (haftungsbeschränkt) angebotenen Leistungen. Abweichenden oder ergänzenden allgemeinen Geschäftsbedingungen der Besteller*innen wird ausdrücklich widersprochen. Abweichungen oder Ergänzungen bedürfen der ausdrücklichen schriftlichen Bestätigung des Anbieters. Mündliche Nebenabreden gelten nicht. Mit dem Absenden der Bestellung erkennen die Besteller*innen diese allgemeinen Geschäftsbedingungen an.</li>
        <br /><li>Verbraucher*in ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend nicht ihrer gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer*in ist jede natürliche oder juristische Person oder rechtsfähige Personengesellschaft, die beim Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.</li>
        <br /><li>Vertragsgegenstand ist der Online-Schlagzeugunterricht über das Medium „Video“ in Form von „Streaming on Demand“ (Online-Schlagzeugunterricht) in Verbindung mit einem individualisierten Stundenplan.</li>
      </ol>

      <h2>§ 2 Vertragsabschluss</h2>
      <ol>
        <li>Mit der Darstellung der Leistungen auf der Internetseite des Anbieters macht der Anbieter den Besteller*innen kein rechtlich bindendes Vertragsangebot. </li>
        <br /><li>Im Falle eines Vertragsschlusses kommt der Vertrag mit <p id="address-center">DrumX UG (haftungsbeschränkt)<br />
          Peter-Dörfler-Str. 6 <br />
          82131 Stockdorf</p>zustande.</li>
        <br /><li>Ihr Angebot auf Abschluss eines Vertrages über Online-Schlagzeugunterricht erfolgt durch die Eingabe der erforderlichen Informationen über unser auf der Internetseite bereitgestelltes Bestellformular und die Übermittlung dieser Daten. Der Bestellvorgang kann nur abgeschlossen werden, wenn Sie die hier vorliegenden AGBen und die Datenschutzerklärung akzeptieren. Die AGB können Sie vor Abgabe eines verbindlichen Angebotes ausdrucken. Ein verbindliches Angebot über den Abschluss eines Unterrichtsvertrages geben Besteller*innen über Bestätigung des Buttons „Subscribe“ ab. </li>
        <br /><li>Um sich zu registrieren, müssen Sie mindestens 18 Jahre alt sein oder mindestens 14 Jahr alt und das Einverständnis Ihrer Eltern oder Ihres Vormundes zu den Vereinbarungen besitzen.</li>
        <br /><li>Der Vertrag kommt erst mit der Annahmeerklärung durch uns zustande, die gesondert in Textform erfolgt. Die Annahmeerklärung kann dadurch ersetzt werden, dass wir mit der Vertragsausführung beginnen, indem wir Sie zur Nutzung der Videos freischalten. Ein Anspruch auf Vertragsschluss besteht nicht. Wir können den Vertragsschluss ohne Begründung ablehnen. Die Übertragung des Vertrages durch die Besteller*in an Dritte bedarf während der Laufzeit des Abonnements der ausdrücklichen Zustimmung durch uns. Eine Vertragsübernahme (Übertragung des Schuldverhältnisses im Ganzen) ist schriftlich an den Anbieter zu senden. Erforderlich sind sowohl die Unterschrift der Vertragsübergeber*in als auch der -übernehmer*in. Die erforderliche Zustimmung des Anbieters erfolgt durch schriftliche Bestätigung.</li>
        <br /><li>Falls wir ohne eigenes Verschulden nicht zur Verfügungstellung einzelnen oder aller Unterrichtsvideos in der Lage sein sollten, sind wir unter Ausschluss sämtlicher weitergehender Ansprüche und Rechte der Besteller*innen zur Nachlieferung zum nächstmöglichen Termin verpflichtet. Dauert die Verzögerung mehr als vier Wochen an, sind beide Seiten zur außerordentlichen Kündigung des Vertrages über Online-Schlagzeugunterricht berechtigt.</li>
        <br /><li>Der Vertragstext mit Angaben zum Stand der AGB wird vom Anbieter gespeichert. Ein Zugriff auf den Vertragstext über das Internet besteht nicht. Dieser wird jedoch in der Bestellbestätigung in Textform mitgeteilt, so dass die Vertragsbestimmungen abgerufen, ausgedruckt oder in wiedergabefähiger Form gespeichert werden können.</li>
        <br /><li>Ein Vertragsschluss ist ausschließlich in deutscher Sprache möglich.</li>
        <br /><li>Maßgeblich ist die jeweils bei Abschluss des Vertrages gültige Fassung der ABGen.</li>
        <br /><li> Der Anbieter kann die AGBen jederzeit und ohne Angabe von Gründen ändern und anpassen. Hierüber wird über die angegebenen Email-Adresse informiert. Bei Nutzung des Angebots gilt die Zustimmung zu den geänderten AGBen als erteilt.</li>
      </ol>
      <h2>§ 3 Art der Leistungserbringung, Vertragslaufzeit und Kündigung</h2>
      <ol>
        <li>Der Online-Schlagzeugunterricht erfolgt über das Medium „Video“ in Form von „Steaming on Demand“. Dies bedeutet, dass Sie zum Unterricht die von uns auf unserer Homepage eingebetteten Videos ansehen können. Ein externes Speichern und Verbreiten werden hiermit ausdrücklich untersagt. Die Videos sind nur während eines aktiven Abonnements zugänglich. </li>
        <br /><li>Zusätzlich erhalten Sie Noten in Form eines pdf-Dokuments und einen Beschreibungstext zum Video. Das pdf-Dokument dürfen Sie herunterladen und speichern, aber nicht weiterverbreiten.</li>
        <br /><li>Wir speichern Übungsstatistiken über Sie mit Angaben über die Länge Ihrer Übungseinheit und der von Ihnen gemachten Einschätzung zur Schwierigkeit der Übung. Ausgehend davon wird ein für Sie passender und auf Sie zugeschnittener Unterrichtsplan erstellt. </li>
        <br /><li>Die Mindestlaufzeit eines Vertrages über Online-Schlagzeugunterricht beträgt einen Monat. Von Ihnen bezahlte Beträge für den laufenden Monat werden nicht anteilig erstattet. Der Vertrag kann jeweils zum Ende der monatlichen Laufzeit gekündigt werden. Die Kündigung erfolgt über die Homepage. Kündigen Sie den Vertrag, sind wir nicht dazu verpflichtet, Ihren Unterrichtsfortschritt und den individuellen Unterrichtsplan zu speichern.</li>
        <br /><li>Wird das Abonnement nicht gekündigt, verlängert es sich jeweils um einen weiteren Monat.</li>
        <br /><li>Sie können auch Ihren Account löschen. Die Löschung erfolgt ebenfalls über die Homepage. Dies führt automatisch zur Kündigung des Vertrages zum nächsten Monatsende. Auch in diesem Fall kommt es nicht zu einer anteiligen Erstattung der bereits gezahlten Monatsgebühr.</li>
        <br /><li>Eine Kündigung aus wichtigem Grund nach Maßgabe der gesetzlichen Bestimmungen bleibt unberührt.</li>
        <br /><li>Vertragssprache ist Deutsch.</li>
      </ol>
      <h2>§ 4 Preise und Zahlung</h2>
      <ol>
        <li>Die angegebenen Preise enthalten die gesetzliche Umsatzsteuer.</li>
        <br /><li>Die Bezahlung erfolgt mittels Kreditkarte, SEPA debit, GooglePay oder ApplePay. Mit Angabe der Kreditkartendaten bzw. der benötigten Kontodaten in der Bestellung ist DrumX UG (haftungsbeschränkt) ermächtigt, den Betrag von Ihrem in der Bestellung angegebenen Konto einzuziehen. Die DrumX UG (haftungsbeschränkt) verwendet Stripe als Zahlungsabwickler.</li>
        <br /><li>Bei eintretenden Preiserhöhungen ist der neue Preis vom Zeitpunkt der Erhöhung an maßgeblich. Hierüber informieren wir Sie über die von Ihnen angegebenen Email-Adresse.</li>
        <br /><li>Bei Zahlungsverzug ist der Anbieter berechtigt, den Vertrag über den Online-Schlagzeugunterricht bis zum Zahlungseingang auszusetzen bzw. diesen fristlos zu kündigen.
        </li>
      </ol>
      <div className="right-of-withdrawal">
        <h2>§ 5 Widerrufsrecht</h2>
        <p>Widerrufsbelehrung<br />Verbraucher*innen haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.</p>
        <p>Der Widerruf ist zu richten an: </p>
        <p>DrumX UG (haftungsbeschränkt)<br />
          Peter-Dörfler-Str. 6 <br />
          82131 Stockdorf</p>
        <p>mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail: info@drumx.com) über Ihren Entschluss, diesen Vertrag zu widerrufen. Diese Erklärung geschieht formlos. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
        <p>Widerrufsfolgen<br />Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
        <p>Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</p>
        <p>Ende der Widerrufsbelehrung</p>
      </div>
      <h2>§ 6 Verfügbarkeit der Videos und der sonstigen Unterrichtsmaterialien</h2>
      <p>Wir sind bemüht, jederzeit einen ordnungsmäßigen Betrieb unserer Homepage sicherzustellen sowie etwaige Ausfälle schnellstmöglich zu beheben. Gelegentlich kann es jedoch zu technischen Schwierigkeiten kommen, die auch zu einer zeitweiligen Unterbrechung führen können. Sie erkennen hiermit an, dass uns keine Verpflichtung zur ununterbrochenen Verfügbarkeit oder zur Aktualisierung unser Dienste oder zur Bereitstellung aller oder spezifischer Inhalte über die Dienste trifft. Insbesondere führen wir regelmäßig Wartungsarbeiten durch, wobei es zu Einschränkungen der Verfügbarkeit kommen kann. </p>
      <p>Zur Nutzung unserer Dienstleistung müssen Sie aktuelle und allgemein gängige Soft- und Hardware verwenden. Dies betrifft den Internetzugang, aktuelle Browsertechnologien usw. Sie müssen auch sicherstellen, dass die von uns angebotenen Dienstleistung erbracht werden kann (z.B. Aktivierung von cookies).</p>
      <h2>§ 7 Datenschutz</h2>
      <p>Wir nutzen Ihre personenbezogenen Daten ausschließlich zur Abwicklung von Verträgen von Besteller*innen. Persönliche Daten werden selbstverständlich vertraulich behandelt. Bitte nehmen Sie dazu auch unsere Datenschutzerklärung zur Kenntnis. Sie finden diese unter folgendem <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Link</a>.</p>
      <h2>§ 8 Urheberrecht</h2>
      <p>Eine Nutzung der jeweiligen Videos und der weiteren zur Verfügung gestellten Unterrichtsmaterialien wird ausschließlich zu eigenen, nichtkommerziellen Zwecken gestattet. Die Weitergabe der Inhalte an Dritte ist untersagt. Eine andere Nutzung bedarf der vorherigen Zustimmung (Einwilligung) im Sinne des § 183 BGB. Es wird darauf hingewiesen, dass eine über den Vertragszweck hinausgehende Nutzung bzw. die Verwertung der urheberrechtlich geschützten Inhalte unzulässig und strafbar ist. </p>
      <h2>§ 9 Streitschlichtung</h2>
      <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE" target="_blank" rel="noopener noreferrer">hier</a> finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.</p>
      <h2>§ 10 Schlussbestimmungen und Gerichtsstand</h2>
      <ol>
        <li>Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. </li>
        <br /><li>Für Besteller*innen, die nicht Verbraucher*innen im Sinne von § 13 BGB sind, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus dem Vertragsverhältnis Starnberg.</li>
        <br /><li>Nebenabreden und Vertragsänderungen bedürfen der Textform.</li>
        <br /><li>Sollten Vereinbarungen mit Besteller*innen, insbesondere Teile der Allgemeinen Vertragsbedingungen vom Anbieter, unwirksam sein oder werden, bleibt die Wirksamkeit des Vertrages in seiner Gesamtheit hiervon unberührt. Anstelle einer etwa unwirksamen Regelung gilt eine wirksame Regelung als vereinbart, die dem wirtschaftlichen Sinn und Zweck der unwirksamen Klausel möglichst nahekommt.</li>
      </ol>
    </div>


  )
}

export default TermsAndConditions;