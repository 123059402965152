import React from "react";
import './privacyPolicy.css'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Datenschutzerklärung</h1>
      <h2>Einleitung</h2>
      <p className="introduction">Die folgende Datenschutzerklärung dient der Aufklärung welche personenbezogenen Daten
        gesammelt und auf welche Art und Weise und zu welchem Zweck diese weiterverarbeitet werden.</p>
      <h2>Inhaltsübersicht</h2>
      <div>
        <ul>
          <li><a href="#responsible">Verantwortlicher</a></li>
          <li><a href="#overview-data-processing">Übersicht der Verarbeitungen</a></li>
          <li><a href="#types-of-data">Arten der verarbeiteten Daten</a></li>
          <li><a href="#categories-of-person">Kategorien betroffener Personen</a></li>
          <li><a href="#purpose-of-processing">Zwecke der Verarbeitung</a></li>
          <li><a href="#legal-text">Maßgebliche Rechtsgrundlage</a></li>
          <li><a href="#security-measures">Sicherheitsmaßnahmen</a></li>
          <li><a href="#third-party-service-provider">Externe Dienstleister</a></li>
          <li><a href="#stripe-explanation">Stripe</a></li>
          <li><a href="#vimeo-explanation">Vimeo</a></li>
          <li><a href="#google-mail-explanation">Google Mail</a></li>
          <li><a href="#google-analytics-explanation">Google Analytics</a></li>
          <li><a href="#processing-in-third-party-countries">Datenverarbeiteung in Drittländern</a></li>
          <li><a href="#duration-of-records">Speicherdauer oder deren Kriterien</a></li>
          <li><a href="#backups">Backups</a></li>
          <li><a href="#update-and-changes-privacy-policy">Änderung und Aktualisierung der Datenschutzerklärung</a></li>
          <li><a href="#rights-of-customers">Betroffenerechte</a></li>
          <li><a href="#use-of-cookies">Einsatz von Cookies</a></li>
          <li><a href="#definitions">Begriffsdefintion</a></li>
        </ul>
      </div>
      <h2 id="responsible">Verantwortlicher</h2>
      <div>
        <p>DrumX UG (haftungsbeschränkt)</p>
        <p>Peter-Dörfler-Str. 6</p>
        <p>82131 Stockdorf</p>
        <p>E-Mail-Adresse: <a href="mailto:info@drumx.com" target="_blank" rel="noopener noreferrer">info@drumx.com</a></p>
        <p>Telefon: +49 (0) 151 648 13 555</p>
        <p>Impressum: <a href="https://drumx.com/impressum">https://drumx.com/impressum</a></p>
      </div>

      <h2 id="overview-data-processing">Übersicht der Verarbeitungen</h2>
      Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und
      verweist auf die betroffenen Personen.
      <h3 id="types-of-data">Arten der verarbeiteten Daten</h3>
      <ul>

        <li>Bestandsdaten.</li>
        <li>Zahlungsdaten.</li>
        <li>Kontaktdaten.</li>
        <li>Vertragsdaten.</li>
        <li>Nutzungsdaten.</li>
        <li>Metadaten.</li>
      </ul>
      <h3 id="categories-of-person">Kategorien betroffener Personen</h3>
      <ul>
        <li>Kunden.</li>
        <li>Nutzer.</li>
        <li>Interresenten.</li>
        <li>Kommunikationspartner.</li>
        <li>Geschäfts- und Vertragspartner.</li>
      </ul>
      <h3 id="purpose-of-processing">Zwecke der Verarbeitung</h3>
      <ul>
        <li>Erbringung vertraglicher Leistungen und Kundensupport.</li>
        <li>Kontaktanfragen und Kommunikation.</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Direktmarketing.</li>
        <li>Feedback.</li>
        <li>Informationstechnische Infrastruktur.</li>
        <li>Marketing.</li>
      </ul>
      <h3 id="legal-text">Maßgebliche Rechtsgrundlage</h3>
      <p>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten
        verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in
        Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
        maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.</p>
      <ul>

        <li><b>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO) </b> – Die betroffene Person hat ihre Einwilligung in die
          Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte
          Zwecke
          gegeben.</li>
        <li><b>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO)</b> – Die Verarbeitung ist
          für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung
          vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.</li>
        <li><b>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)</b> – Die Verarbeitung ist zur Erfüllung einer
          rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt.</li>
        <li><b>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</b> – Die Verarbeitung ist zur Wahrung der
          berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder
          Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern,
          überwiegen.</li>
      </ul>
      <p>Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz
        in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
        Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf
        Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener
        Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im
        Einzelfall einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des
        Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder Beendigung
        von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können Landesdatenschutzgesetze der
        einzelnen Bundesländer zur Anwendung gelangen.</p>
      <h2 id="security-measures">Sicherheitsmaßnahmen</h2>
      <p>Alle Daten unterliegen Sicherheitsmaßnahmen nach aktuellem technischen Stand um die Vertaulichkeit, Integrität und Verfügbarkeit in Rahmen eines
        vertretbaren Aufwands zu gewährleisten. Passwörter werden sowohl bei Übertragung (https) als auch bei Speicherung
        verschlüsselt. Backups um Verfügbarkeit zu gewährleisten werden erstellt.
        An dieser Stelle sei hingewiesen, dass es keinen 100%igen Schutz gibt, jedoch nach bestem Wissen und Gewissen
        Sicherheitsmaßnahmen implementiert sind und aktuell gehalten werden.
      </p>
      <h2 id="third-party-service-provider">Externe Dienstleister</h2>
      <p>Es werden folgende externe Dienstleister verwendet: <br />
        Stripe für die Zahlungsabwicklung.<br />
        Vimeo als zur Einbindung von Videomaterial (Content delivery network).<br />
        Google als Email-Provider: Funktionale E-Mails (E-Mail Verifikation, Passwort Wiederherstellung, Newsletter (falls OPT-In erfolgt)).<br />
        DigitalOcean als Hoster.
      </p>

      <h3 id="stripe-explanation">Stripe</h3>
      <p>Stripe ist ein Dienstleister der die Zahlungsabwicklung übernimmt. Das heißt, solange ein Abonnement besteht, speichert Stripe Daten. Dazu zählt die jeweilige ausgewählte Zahlungsmethode (z.B. Kredit/Debitkartennummer etc.).
        Außerdem werden Daten wie Name, Postleitzahl, E-Mail Adresse, Betrag und Datum der Zahlung übermittelt. Um Betrug vorzubeugen auch Browserbezogene Daten ihres Geräts von Stripe gesammelt, wie IP-Adresse,Browsertyp (User-Agent) und Betriebssytem. Stripe verwendet diese Daten nur um ihre Leistung sicher zu stellen.
        <br />Die Datenschutzerklärung von Stripe findet man <a href="https://stripe.com/en-de/privacy" target="_blank" rel="noopener noreferrer">hier</a>. Die cookie policy findet man <a href="https://stripe.com/en-de/legal/cookies-policy" target="_blank" rel="noopener noreferrer">hier</a>.
      </p>
      <h4>Stripe erhält auf folgende Daten Zugriff:</h4>
      <ul>
        <li>Vor- und Nachnamen</li>
        <li>Adresse</li>
        <li>E-Mail-Adresse</li>
        <li>IP-Adresse</li>
        <li>Telefonnummer</li>
        <li>Kreditkartenummer</li>
        <li>Kontodeckung</li>
        <li>Umsätze </li>
        <li>Kreditrahmen des Dispokredits</li>
      </ul>
      <h3 id="vimeo-explanation">Vimeo</h3>
      <p>Vimeo ist eine Video Plattform und wird als Content Delivery Network (CDN) verwendet. Videos sind ein wichtiger Bestandteil des Produkts. Um den vollen Umfang nutzen zu können empfehlen wir Vimeo cookies zuzulassen.
        Wir vemeiden unnötiges Sammeln von Daten und achten darauf, dass alle eingebundenen Videos das dnt=1 flag übergeben bekommen. "dnt" ist die Abkürzung für "do not track" - übersetzt: "nicht verfolgen". Vimeo setzt in diesem Fall nur ihre eigenen Cookies, aber keine cookies Drittanbieter.
        Die cookie policy von Vimeo findet man <a href="https://vimeo.com/cookie_policy" target="_blank" rel="noopener noreferrer">hier</a>, die Datenschutzerklärung <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">hier</a>
      </p>
      <h3 id="google-mail-explanation">Google Mail</h3>
      <p>Funktionale E-Mails (Passwort-Wiederherstellung/E-Mail Verifizierung und Kundenkontakt) werden über Google Mail versendet. Google speichert hierfür die E-Mail Adresse.
        Die Google Datenschutzerklärung findet man <a href="https://policies.google.com/privacy?hl=de-DE">hier</a>.
      </p>
      <h3 id="google-analytics-explanation">Google Analytics</h3>
      <p>Google Analytics wird verwendet, um detaillierte Einblicke in das Benutzerverhalten und die Performance der Website zu erhalten. Mit Hilfe von Google Analytics können wichtige Informationen wie Seitenaufrufe, Besucherstatistiken, Verweildauer und demografische Daten analysiert werden. 
        Diese Daten helfen dabei, das Nutzerverhalten zu verstehen, das Website-Design und die Inhalte zu optimieren sowie Marketingstrategien zu verbessern. 
        Durch die Verwendung von Google Analytics kann die Online-Präsenz zu verbessert und die Benutzererfahrung optimiert werden.
        Die Google Datenschutzerklärung findet man <a href="https://policies.google.com/privacy?hl=de-DE">hier</a>.
        </p>
      <p>Um generell Google Analytics zu umgehen ist folgendes Browser-Plugin empfohlen: <a href="https://tools.google.com/dlpage/gaoptout">opt-out plugin</a></p>
      <h4>Google Analytics sammelt folgende Daten:</h4>
      <ul>
        <li>IP-Adresse (anonymisiert)</li>
        <li>Benutzer-ID</li>
        <li>Anzahl der User</li>
        <li>Ungefähre Geolokalisierung</li>
        <li>Browser Informationen</li>
        <li>Geräteinformationen</li>
        <li>Seitenaufrufe</li>
        <li>Verweisende Seiten</li>
        <li>Verweisende Suchmaschinen</li>
        <li>Scrollverhalten</li>
        <li>Interaktionen mit Formularen</li>
        <li>Interaktionen mit Videos</li>
      </ul>
      <h3 id="digital-ocean-explanation">DigitalOcean</h3>
      <p>Die Website wird auf Servern von DigitalOcean betrieben. Die ausgewählte Instanz ist in Frankfurt. Alle Daten werden auf diesem Server gespeichert. Die Datenschutzerklärung von DigitalOcean findet man <a href="https://www.digitalocean.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">hier.</a></p>
      <h2 id="processing-in-third-party-countries">Datenverarbeiteung in Drittländern</h2>
      <p>Sowohl Stripe, Vimeo und Google können Daten außerhalb der Europäischen Union(EU) verarbeiten.</p>
      <h2 id="duration-of-records">Speicherdauer oder deren Kriterien</h2>
      <h2>Allgemeine Speicherung</h2>
      <p>Daten werden für die Dauer eines aktiven oder pausierten Abonnements gespeichert. Ein Abonnement gilt als pausiert
        falls eine Kündigung ohne Accountlöschung vorliegt. Die Dauer der Pausierung kann den AGB's entnommen werden. Mit
        Auslösen des Löschprozesses (nach Ablauf der Pausierung oder nach direkter Anfrage) werden alle Daten gelöscht.</p>
      <p>In diesem Löschprozess wird ein Aufruf zum externe Dienstleister Stripe getätigt um dessen Löschprozess anzustoßen.</p>
      <h3 id="backups">Backups</h3>
      <p>Backups werden täglich erstellt. Diese dürfen bis zu 30 Tage aufgehoben werden um im Falle eines technischen
        Ausfalls höchstmögliche Verfügbarkeit zu garantieren. Das heißt, dass in den Backups Daten enthalten, die bereits
        gelöscht wurden noch in Backups enthalten sein werden
        für bis zu 30 Tage.</p>
      <h2 id="update-and-changes-privacy-policy">Änderung und Aktualisierung der Datenschutzerklärung</h2>
      <p>Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen.
        Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>
      <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.</p>
      <h2 id="rights-of-customers">Betroffenerechte</h2>
      <p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO
        ergeben:</p>
      <ul>
        <li><b>Widerspruchsrecht: </b>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
          jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit.
          e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
          Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben
          Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
          Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
          Verbindung steht.</li>
        <li><b>Widerrufsrecht bei Einwilligungen: </b>Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
        </li>
        <li><b>Auskunftsrecht: </b>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten
          verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten
          entsprechend den gesetzlichen Vorgaben.</li>
        <li><b>Recht auf Berichtigung: </b>Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung
          der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.</li>
        <li><b>Recht auf Löschung und Einschränkung der Verarbeitung: </b>Sie haben nach Maßgabe der gesetzlichen Vorgaben
          das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der
          gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.</li>
        <li><b>Recht auf Datenübertragbarkeit: </b>Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt
          haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu
          erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.</li>
        <li><b>Beschwerde bei Aufsichtsbehörde: </b>Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
          ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der
          Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO
          verstößt.</li>
      </ul>

      <h2 id="use-of-cookies">Einsatz von Cookies</h2>
      <p>Cookies sind kleine Textdateien die auf Endgeräten gespeichert werden. Cookies werden verwendet um die Funktionalität der Seite zu ermöglichen.</p>
      <p><b>Hinweise zur Einwilligung: </b>Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern und das Auslesen der Informationen, also auch von Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche Einwilligung wird gegenüber den Nutzern deutlich kommuniziert und enthält die Informationen zu der jeweiligen Cookie-Nutzung.</p>
      <p><b>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:: </b>Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- und Verarbeitungsprozessen auf.</p>
      <br />
      <p><b>Speicherdauer: </b>Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies unterschieden:</p>
      <ul>
        <li><b>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies): </b>Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile Applikation) geschlossen hat.</li>
        <li><b>Permanente Cookies: </b>Permanente Cookies bleiben auch nach dem Schließen des Endgerätes gespeichert. So können beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet werden. Sofern wir Nutzern keine expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.</li>
      </ul>
      <p><b>Cookie Typen:</b></p>
      <p>Essentielle Cookies werden benötigt um einen minimalen Funktionsumfang zu gewährleisten. Funktionale Cookies werden verwendet damit ein gutes Erlebnis garantiert werden kann. Leistungs Cookies sammeln Daten über das Nutzerverhalten. Diese werden verwendet um das Nutzererlebnis zu verbessern und Inhalte gezielter zu platzieren.
        Wir verwenden keine Werbe Cookies. </p>

      <ul>
        <li>
          <p><b>Essentielle Cookies:</b>  </p>
          <ul>
            <li><b>First Party Cookies:</b>
              <ul><li>Authorisierungs Cookie: Ermöglicht einen Sessionähnlichn Zustand. Identifiziert den Kunden und ermöglicht so den Zugriff auf die Leistung.</li></ul></li>
            <li><b>Third Party Cookies:</b>
              <ul><li>Stripe: Ermöglicht die Zahlungsabwicklung und beugt Betrug vor.</li></ul>
            </li>
          </ul>
        </li>
        <li><p><b>Funktionale Cookies:</b></p></li>
        <ul><li><b>Third Party Cookies:</b>
          <ul>
            <li>Vimeo: Vimeo setzt nur First Party Cookies (keine Drittanbieter Cookies ihrerseits) um die Funktionalität Vimeos zu garantieren.</li>
          </ul>
        </li>
        </ul>
        <li><p><b>Leistung Cookies:</b></p></li>
        <ul><li><b>Third Party Cookies:</b>
          <ul>
            <li>Google Analytics: Diese Daten helfen dabei, das Nutzerverhalten zu verstehen, das Website-Design und die Inhalte zu optimieren sowie Marketingstrategien zu verbessern.</li>
          </ul>
        </li>
        </ul>
      </ul>
      <h2 id="definitions">Begriffsdefinition</h2>
      <p>In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert.
        Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch sortiert.</p>
      <ul>
        <li><b>Personenbezogene Daten: </b>„Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.</li>
        <li><b>Verantwortlicher: </b> „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.</li>
        <li><b>Verarbeitung: </b>„Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen. </li>      </ul>
    </div>

  )
}

export default PrivacyPolicy;