import http from "./rest";

const scheduleServiceEndpoint = process.env.REACT_APP_SCHEDULESERVICE_URL;

async function receiveSchedule(authToken, skillPreference, practiceTime, padSufficient) {

  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  };

  let payload = {
    skillPreference: skillPreference,
    practiceTime: practiceTime,
    padSufficient: padSufficient
  };

  try {
    return await http.post(scheduleServiceEndpoint + "/api/lesson/schedule", payload, config);
  } catch (err) {
    console.log("could not retrieve schedule", err);
    throw err;
  }

}

async function getLesson(authToken, skillPref = 'random') {

  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    },
    params: {
      skillPref: skillPref
    }
  };

  let res;
  await http.get(scheduleServiceEndpoint + "/api/lesson", config)
    .then((response) => {
      res = response;
    })
    .catch((err) => { console.error("catch axios get" + err); throw err; });

  return res;
}

async function getSkillPreference(authToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  };

  try{
    const response = await http.get(scheduleServiceEndpoint + "/api/focus", config);
    return response;
  } catch(err){
    console.error("catch axios get" + err); 
    throw err;
  }
}


// why not async?
const postSkillPreference = (authToken, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  payload = { skillSelfRating: payload }
  return http.post(scheduleServiceEndpoint + "/api/focus", payload, config);
}

async function postHistoryEntry(authToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  };

  try {
    await http.post(scheduleServiceEndpoint + "/api/lesson/finishExercise", payload, config);
  } catch (err) {
    console.error("finish lesson failed" + err);
  }
}


async function getMusicSheet(authToken, param) {
  const config = {
    headers: {
      "Accept": "application/pdf",
      "x-auth-token": authToken
    },
    responseType: 'blob',
    params: {
      lessonId: param
    }
  };

  let res;
  await http.get(scheduleServiceEndpoint + "/api/file/download", config)
    .then((response) => {
      res = response;
    })
    .catch((err) => { console.error("catch axios get " + err); throw err; });
  return res;
}

async function getProgressCounts(authToken) {

  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  };

  try {
    const response = await http.get(scheduleServiceEndpoint + "/api/lesson/progress", config);
    return response.data;
  } catch (err) {
    console.log("could not progress", err);
  }
}

async function updateSkillPreference(authToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  };

  try {
    await http.patch(scheduleServiceEndpoint + "/api/focus", payload, config);
  } catch (err) {
    console.error("updating skill preference failed: " + err);
  }
}

async function deleteCustomerData(authToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.delete(scheduleServiceEndpoint + "/api/delete/customer-data", config);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getCustomerPracticeActivity(authToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.get(scheduleServiceEndpoint + "/api/practiceActivity", config);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function postCustomerPracticeActivity(authToken, payload) { 
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.post(scheduleServiceEndpoint + "/api/practiceActivity", payload, config);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

async function getCustomerProgress(authToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": authToken
    }
  }
  try {
    return await http.get(scheduleServiceEndpoint + "/api/progress", config);
  } catch (err) {
    console.log(err);
    throw err;
  }
}


export { receiveSchedule, getLesson, postSkillPreference, postHistoryEntry, getSkillPreference, getMusicSheet, getProgressCounts, updateSkillPreference, deleteCustomerData, getCustomerPracticeActivity, postCustomerPracticeActivity, getCustomerProgress }