import React, { useState, useEffect } from 'react';
import { formatTime } from "../util/formatTime"
import "./stopwatch.css";


function Stopwatch({ stopWatchTime, onTimeUpdate }) {
  const [currentTime, setCurrentTime] = useState(0);
  const [pause, setPause] = useState(false);
  useEffect(() => {
    setCurrentTime(stopWatchTime);
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!pause) {
        setCurrentTime((prevTime) => prevTime + 1);
        onTimeUpdate(currentTime + 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [onTimeUpdate, currentTime, pause]);



  return (
    <div className="stopwatch">
      <button onClick={() => { setPause(!pause) }}>
        {pause ?
          <div className="play"></div>
          :
          <div className="pause"></div>
        }
      </button>
      <div>{formatTime(stopWatchTime)}</div>
    </div>
  );
}

export default Stopwatch;
