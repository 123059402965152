import React from "react";
import { Route, Redirect } from "react-router-dom";
import { deleteAuthToken, hasAuthTokenStored, isAuthCookieExpired } from "../util/auth";


const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        // very simple check - resources still need to be protected server side
        if (!hasAuthTokenStored()){
          console.log("no auth token")
          return <Redirect to="/" />;
        } 
        if(isAuthCookieExpired()){
          deleteAuthToken();
          return <Redirect to="/log-out" />;
        }
        // todo improve hacky solution
        //const user = jtwDecode(getAuthToken());
        //console.log("protected route", user.verification_state)
        //if (hasAuthTokenStored() && !user.verification_state) return <Redirect to="/verify-mail" />;
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
